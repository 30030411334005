<template>
  <div class="container">
    <a-card :bordered="false" style="border-radius: 11px;">
        <span>日程安排</span>
    </a-card>
  </div>
</template>
   
<script>
   
export default {
  name:'',
  data(){
    return {
    }
  },
  created() {
    
  },
  methods: {
  
  },
}
</script>
   
<style lang="less" scoped>
  .container {
    padding: 21px;
  }
</style>