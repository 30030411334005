import axios from 'axios';

const http = axios.create({
    // baseURL: 'https://www.zhfyun.com',
    baseURL: 'http://172.20.0.81:888',
    timeout: 30000,
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded',
        // 'cookie' : "JSESSIONID=" + JSON.parse(localStorage.getItem('userInfo')).jsessionid,
    }
});

// 请求拦截器
http.interceptors.request.use(function(config){
    // 发送请求之前
    return config
},function(error){
    // 请求错误
    return Promise.reject(error);
});

/// 响应拦截器
http.interceptors.response.use(function(response){
    // 响应数据处理
    return response
},function(error){
    // 响应失败
    return Promise.reject(error)
});

export default http