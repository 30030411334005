<template>
  <div class="mainContainer">
    <router-view/>
  </div>
</template>
   
<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
   
export default {
  components: { CommonAside, CommonHeader },
  name:'',
  data(){
    return {
    }
  },
  created() {
    
  },
  methods: {
  
  },
}
</script>
   
<style lang="less" scoped>
  
// .mainContainer :deep(.arco-layout-header),
// .mainContainer :deep(.arco-layout-footer),
// .mainContainer :deep(.arco-layout-sider-children),
// .mainContainer :deep(.arco-layout-content) {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     color: var(--color-white);
//     font-size: 16px;
//     font-stretch: condensed;
//     text-align: center;
// }
</style>