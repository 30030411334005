<template>
  <div class="container" :style="{ width: menuWidth + 'px' }">
    <a-menu
      :style="{ width: 'auto', height: 'calc(100% - 28px)' }"
      breakpoint="xl"
      :collapsed="collapse"
      accordion
    >
        <div class="open" @click="toggleCollapse">
            <a-menu-item key="0">
                <template #icon><icon-menu-unfold></icon-menu-unfold></template>
                {{ !collapse ? '收起导航' : '展开导航' }}
            </a-menu-item>
        </div>
        <!-- <div class="menu" v-for="(item,index) in menuArray" :key="index">
            <a-menu-item :key="index" v-if="!item.children">
                <template #icon><icon-apps></icon-apps></template>
                {{ item.label }}
            </a-menu-item>
            <a-sub-menu :key="item.label" v-else>
                <template #icon><icon-menu-fold></icon-menu-fold></template>
                <template #title>{{ item.label }}</template>
                <a-menu-item :key="`${index}-${cIndex}`" v-for="(child,cIndex) in item.children">{{ child.label }}</a-menu-item>
            </a-sub-menu>
        </div> -->
        <div class="menu">
            <a-menu-item>
                <template #icon><icon-home></icon-home></template>
                首页
            </a-menu-item>
            <a-sub-menu>
                <template #icon><icon-file></icon-file></template>
                <template #title>消息公告</template>
                <a-menu-item>公告</a-menu-item>
                <a-menu-item>消息</a-menu-item>
                <a-menu-item>我的</a-menu-item>
                <a-menu-item>图表展示</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-layers></icon-layers></template>
                <template #title>工单管理</template>
                <a-menu-item>现场工单</a-menu-item>
                <a-menu-item>咨询工单</a-menu-item>
                <a-menu-item>内部工单</a-menu-item>
                <a-menu-item>寄修工单</a-menu-item>
                <a-menu-item>督办单</a-menu-item>
                <a-menu-item>催办单</a-menu-item>
                <a-menu-item>超时工单</a-menu-item>
                <a-menu-item>远程协同</a-menu-item>
                <a-menu-item>通话记录</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-layout></icon-layout></template>
                <template #title>项目管理</template>
                <a-menu-item>安装项目</a-menu-item>
                <a-menu-item>勘测工单</a-menu-item>
                <a-menu-item>发货工单</a-menu-item>
                <a-menu-item>安装工单</a-menu-item>
                <a-menu-item>工单周报</a-menu-item>
                <a-menu-item>出差申请</a-menu-item>
                <a-menu-item>POC项目</a-menu-item>
                <a-menu-item>适配调试</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-pushpin></icon-pushpin></template>
                <template #title>备件管理</template>
                <a-menu-item>备件申请</a-menu-item>
                <a-menu-item>备件出库</a-menu-item>
                <a-menu-item>备件库存</a-menu-item>
                <a-menu-item>备件入库</a-menu-item>
                <a-menu-item>备件调拨</a-menu-item>
                <a-menu-item>备件盘点</a-menu-item>
                <a-menu-item>备件查询</a-menu-item>
                <a-menu-item>备件预警</a-menu-item>
                <a-menu-item>备件计划</a-menu-item>
                <a-menu-item>仓库档案</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-translate></icon-translate></template>
                <template #title>结算管理</template>
                <a-menu-item>价格管理</a-menu-item>
                <a-menu-item>服务结算</a-menu-item>
                <a-menu-item>工时结算</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-mind-mapping></icon-mind-mapping></template>
                <template #title>绩效考核</template>
                <a-menu-item>内部评价</a-menu-item>
                <a-menu-item>客户评价</a-menu-item>
                <a-menu-item>综合评分</a-menu-item>
                <a-menu-item>考核指标</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-common></icon-common></template>
                <template #title>产品管理</template>
                <a-menu-item>产品信息</a-menu-item>
                <a-menu-item>备件信息</a-menu-item>
                <a-menu-item>产品BOM</a-menu-item>
                <a-menu-item>退换货统计</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-unordered-list></icon-unordered-list></template>
                <template #title>服务组织</template>
                <a-menu-item>服务商</a-menu-item>
                <a-menu-item>工程师</a-menu-item>
                <a-menu-item>地理位置</a-menu-item>
                <a-menu-item>行程轨迹</a-menu-item>
                <a-menu-item @click="pushToItem('Schedule')">工作日历</a-menu-item>
                <a-menu-item>服务商等级</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-user-group></icon-user-group></template>
                <template #title>客户管理</template>
                <a-menu-item>客户信息</a-menu-item>
                <a-menu-item>客户注册</a-menu-item>
                <a-menu-item>客户留言</a-menu-item>
                <a-menu-item>客户投诉</a-menu-item>
                <a-menu-item>客户满意度</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-relation></icon-relation></template>
                <template #title>服务营销</template>
                <a-menu-item>服务合同</a-menu-item>
                <a-menu-item>服务计划</a-menu-item>
                <a-menu-item>服务任务</a-menu-item>
                <a-menu-item>数字展厅</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-book></icon-book></template>
                <template #title>知识管理</template>
                <a-menu-item>知识库</a-menu-item>
                <a-menu-item>故障库</a-menu-item>
            </a-sub-menu>
            <a-sub-menu>
                <template #icon><icon-bar-chart></icon-bar-chart></template>
                <template #title>基础数据</template>
                <a-menu-item>通用字典</a-menu-item>
                <a-menu-item>计量单位</a-menu-item>
                <a-menu-item>工作日历</a-menu-item>
                <a-menu-item>APP版本</a-menu-item>
                <a-menu-item>日程任务</a-menu-item>
                <a-menu-item>物流详情</a-menu-item>
                <a-menu-item>行政区域</a-menu-item>
                <a-menu-item>附件管理</a-menu-item>
            </a-sub-menu>
        </div>
    </a-menu>
  </div>
</template>
   
<script>
import {
  IconMenuUnfold,
  IconHome,
  IconFile,
  IconLayers,
  IconLayout,
  IconPushpin,
  IconTranslate,
  IconMindMapping,
  IconCommon,
  IconRelation,
  IconUserGroup,
  IconUnorderedList,
  IconBook,
  IconBarChart
} from '@arco-design/web-vue/es/icon';
export default {
    components: {
    IconBarChart,
    IconMenuUnfold,
    IconHome,
    IconFile,
    IconLayers,
    IconLayout,
    IconPushpin,
    IconTranslate,
    IconMindMapping,
    IconCommon,
    IconRelation,
    IconUserGroup,
    IconUnorderedList,
    IconBook
  },
  name:'',
  data(){
    return {
        collapse: true,
        menuWidth: 50,
        menuArray: [
            {
                icon: 'icon-apps',
                name: 'home',
                label: '首页',
                path: '/home',
            },
            {
                icon: 's-home',
                name: 'message',
                label: '消息公告',
                path: '/message',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '公告',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '消息',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '我的',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '图表展示',
                        path: '',
                    },
                ]
            },
            {
                icon: 's-order',
                name: 'home',
                label: '工单管理',
                path: '/order',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '现场工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '咨询工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '内部工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '寄修工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '督办单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '催办单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '超时工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '远程协同',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '通话记录',
                        path: '',
                    },
                ]
            },
            {
                icon: 'files',
                name: '',
                label: '项目管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '安装项目',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '勘测工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '发货工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '安装工单',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '工单周报',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '出差申请',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: 'POC项目',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '适配调试',
                        path: '',
                    }
                ]
            },
            {
                icon: 'bank-card',
                name: '',
                label: '备件管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '备件申请',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件出库',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件库存',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件入库',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件调拨',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件盘点',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件查询',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件预警',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件计划',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '仓库档案',
                        path: '',
                    },
                ]
            },
            {
                icon: 'shopping-cart-2',
                name: '',
                label: '结算管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '价格管理',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '服务结算',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '工时结算',
                        path: '',
                    },
                ]
            },
            {
                icon: 'edit-outline',
                name: '',
                label: '绩效考核',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '内部评价',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '客户评价',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '综合评分',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '考核指标',
                        path: '',
                    },
                ]
            },
            {
                icon: 'wallet',
                name: '',
                label: '产品管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '产品信息',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '备件信息',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '产品BOM',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '退换货统计',
                        path: '',
                    },
                ]
            },
            {
                icon: 'c-scale-to-original',
                name: '',
                label: '服务组织',
                path: '',
                children: [
                {
                        icon: '',
                        name: '',
                        label: '服务商',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '工程师',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '地理位置',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '行程轨迹',
                        path: '',
                    },{
                        icon: '',
                        name: '',
                        label: '工作日历',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '服务商等级',
                        path: '',
                    },
                ]
            },
            {
                icon: 's-custom',
                name: '',
                label: '客户管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '客户信息',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '客户注册',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '客户留言',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '客户投诉',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '客户满意度',
                        path: '',
                    },
                ]
            },
            {
                icon: 'service',
                name: '',
                label: '服务营销',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '服务合同',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '服务计划',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '服务任务',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '数字展厅',
                        path: '',
                    },
                ]
            },
            {
                icon: 'notebook-1',
                name: '',
                label: '知识管理',
                path: '',
                children: [
                    {
                        icon: '',
                        name: '',
                        label: '知识库',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '故障库',
                        path: '',
                    },
                ]
            },
            {
                icon: 's-data',
                name: '',
                label: '基础数据',
                path: '',
                children: [
                {
                        icon: '',
                        name: '',
                        label: '通用字典',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '计量单位',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '工作日历',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: 'APP版本',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '日程任务',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '物流详情',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '行政区域',
                        path: '',
                    },
                    {
                        icon: '',
                        name: '',
                        label: '附件管理',
                        path: '',
                    },
                ]
            },
        ],
        openKeys: ["a","b","c"]
    }
  },
  created() {
    
  },
  methods: {
    toggleCollapse() {
        this.collapse = !this.collapse
        this.menuWidth = this.collapse ? 50 : 200
    },
    pushToItem(routerName) {
        this.$router.push({
            name: routerName
        })
    },
  },
}
</script>
   
<style lang="less" scoped>
  .container {
    height: calc(100%);
}

</style>