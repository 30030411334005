<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
      <div
        class="page1-middle"
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 54px;
          color: #ffffff;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 90px;
        "
      >
        <span>新闻中心</span>
      </div>
      <div class="page1-bottom">
        <div class="btn-item">公司新闻</div>
        <!-- <div class="btn-item">媒体聚焦</div> -->
      </div>
    </div>
    <!-- 第二页 公司新闻 -->
    <div class="about-page2">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 28%;
        "
      >
        <span
          style="
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: bold;
            font-size: 40px;
            color: #0b1030;
            line-height: 58px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          "
          >公司新闻</span
        >
      </div>
      <div
        style="
          display: flex;
          width: 72%;
          height: 56%;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 22%;
            height: 100%;
          "
          @click="jumpToDetail(0)"
        >
          <div class="news-item-top"></div>
          <div
            style="
              background-color: #ffffff;
              width: 100%;
              height: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 85%;
                width: 80%;
              "
            >
              <span
                style="
                  font-family: OPPOSans, OPPOSans;
                  font-weight: normal;
                  font-size: 22px;
                  color: #666666;
                  line-height: 67px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >05-21</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #1759d8;
                  line-height: 29px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >我司商标注册申请已初步审定并公告</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 16px;
                  color: #777985;
                  line-height: 24px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >热烈祝贺我司商标注册申请已于2024年5月20日初步审定并公告（第1888期《商标公告》）...</span
              >
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 22%;
            height: 100%;
          "
          @click="jumpToDetail(1)"
        >
          <div class="news-item-top1"></div>
          <div
            style="
              background-color: #ffffff;
              width: 100%;
              height: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 85%;
                width: 80%;
              "
            >
              <span
                style="
                  font-family: OPPOSans, OPPOSans;
                  font-weight: normal;
                  font-size: 22px;
                  color: #666666;
                  line-height: 67px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >04-22</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #1759d8;
                  line-height: 29px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >重塑品牌标识：我们新的公司Logo诞生了</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 16px;
                  color: #777985;
                  line-height: 24px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >我们很高兴地向大家宣布，我们的公司Logo已经完成了全新的设计和重塑...</span
              >
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 22%;
            height: 100%;
          "
          @click="jumpToDetail(2)"
        >
          <div class="news-item-top2"></div>
          <div
            style="
              background-color: #ffffff;
              width: 100%;
              height: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 85%;
                width: 80%;
              "
            >
              <span
                style="
                  font-family: OPPOSans, OPPOSans;
                  font-weight: normal;
                  font-size: 22px;
                  color: #666666;
                  line-height: 67px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >09-24</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #1759d8;
                  line-height: 29px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >丰樾科技携手中国长城，为信创...</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 16px;
                  color: #777985;
                  line-height: 24px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >近日，北京丰樾科技有限公司（以下简称：丰樾科技）签约中国长城科技集团股份有限公司...</span
              >
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 22%;
            height: 100%;
          "
          @click="jumpToDetail(3)"
        >
          <div class="news-item-top3"></div>
          <div
            style="
              background-color: #ffffff;
              width: 100%;
              height: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 85%;
                width: 80%;
              "
            >
              <span
                style="
                  font-family: OPPOSans, OPPOSans;
                  font-weight: normal;
                  font-size: 22px;
                  color: #666666;
                  line-height: 67px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >09-04</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #1759d8;
                  line-height: 29px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >(转)集智达AMS系统项目启动会成功举办...</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 16px;
                  color: #777985;
                  line-height: 24px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >2023年9月4日，集智达&丰樾AMS(售后服务)系统项目启动会在北京集智达公司总部成功举行...</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第三页 公司新闻 -->
    <!-- <div class="about-page3">
      <div
        style="
          width: 72%;
          height: 25%;
          margin-left: 14%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          style="
            width: 30%;
            height: 60px;
            box-shadow: 4px 0 46px 0px #0196;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3%;
          "
        >
          <span
            style="
              font-family: SourceHanSansSC, SourceHanSansSC;
              font-weight: 400;
              font-size: 18px;
              color: #888888;
              line-height: 36px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-left: 30px;
            "
            >按照年份查找</span
          >
          <img
            style="width: 16px; height: 16px; margin-right: 20px"
            src="../assets/news_down_arrow.png"
            alt=""
          />
        </div>
        <div
          style="
            width: 65%;
            height: 60px;
            box-shadow: 4px 0 46px 0px #0196;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3%;
          "
        >
          <span
            style="
              font-family: SourceHanSansSC, SourceHanSansSC;
              font-weight: 400;
              font-size: 18px;
              color: #888888;
              line-height: 36px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-left: 30px;
            "
            >请输入您要搜索的关键字</span
          >
          <img
            style="width: 26px; height: 26px; margin-right: 20px"
            src="../assets/news_search.png"
            alt=""
          />
        </div>
      </div>
      <div
        style="
          width: 72%;
          height: 65%;
          display: flex;
          margin-left: 14%;
          justify-content: space-between;
          flex-wrap: wrap;
        "
      >
        <div class="bottom-news-item">
          <span class="bottom-news-date">12-25</span>
          <span class="bottom-news-title"
            >丰樾科技面向大中型企业销售管理领域提供的基于自主知识产权</span
          >
          <span class="bottom-news-content"
            >自主创新行业龙头企业、湖南商用密码产业示范基地等荣誉资质，4个国家级创新平台，专利总数1300余项...</span
          >
        </div>
        <div class="bottom-news-item">
          <span class="bottom-news-date">11-21</span>
          <span class="bottom-news-title"
            >中国长城曾研发出我国第一台具有自主知识产权</span
          >
          <span class="bottom-news-content"
            >金融信息化、医疗信息化等领域系列国家和行业标准的起草单位之一。目前，中国长城旗下拥有90家控股...</span
          >
        </div>
        <div class="bottom-news-item">
          <span class="bottom-news-date">09-22</span>
          <span class="bottom-news-title"
            >拥有多个国家技术创新示范企业、高新技术企业、自主创新行业龙头企业</span
          >
          <span class="bottom-news-content"
            >随着信创领域竞争白热化，很多产品趋于同质化，市场竞争格局由产品转向了服务。随着客户对服务体验的要求提升...</span
          >
        </div>
        <div class="bottom-news-item">
          <span class="bottom-news-date">07-05</span>
          <span class="bottom-news-title"
            >随着客户对服务体验的要求提升，后市场服务能力是当前信创领域的竞争焦点</span
          >
          <span class="bottom-news-content"
            >对服务的协同效率提出了更高的要求。然而中国长城现有各类系统烟囱式部署，数据以及流程的割裂导致协同效率低下......</span
          >
        </div>
        <div class="bottom-news-item">
          <span class="bottom-news-date">01-21</span>
          <span class="bottom-news-title"
            >随着中国长城业务的发展，服务客户数的激增</span
          >
          <span class="bottom-news-content"
            >客户服务接入单一，产品以及客户信息闭塞，无法形成直观的数据展示；服务经营过程中对备件的精细化管理要求让服务数字化...</span
          >
        </div>
        <div class="bottom-news-item">
          <span class="bottom-news-date">10-22</span>
          <span class="bottom-news-title"
            >数据以及流程的割裂导致协同效率低下；数量庞大的售后工程师</span
          >
          <span class="bottom-news-content"
            >数字化转型的需求更加凸显；而针对全国近2000家服务商的管理与技术支持等，也是中国长城不可忽视的痛点......</span
          >
        </div>
      </div>
    </div> -->

    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      newsData: [
        {
          newsTitle: "我司商标注册申请已初步审定并公告",
          subTitle:
            "热烈祝贺我司商标注册申请已于2024年5月20日初步审定并公告（第1888期《商标公告》）...",
          newsDate: "05-21",
          imagePath: 'url(\'../assets/new-pic01.png\')',
        },
        {
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          subTitle:
            "我们很高兴地向大家宣布，我们的公司Logo已经完成了全新的设计和重塑...",
          newsDate: "04-22",
          imagePath: 'url(\'../assets/new-pic01.png\')',
        },
        {
          newsTitle: "丰樾科技携手中国长城，为信创...",
          subTitle:
            "近日，北京丰樾科技有限公司（以下简称：丰樾科技）签约中国长城科技集团股份有限公司...",
          newsDate: "09-24",
          imagePath: 'url(\'../assets/new-pic01.png\')',
        },
        {
          newsTitle: "(转)集智达AMS系统项目启动会成功举办...",
          subTitle:
            "2023年9月4日，集智达&丰樾AMS(售后服务)系统项目启动会在北京集智达公司总部成功举行...",
          newsDate: "09-04",
          imagePath: 'url(\'../assets/new-pic01.png\')',
        },
      ],
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    jumpToDetail(index) {
      if(index == 0) {
        this.$router.push("/newsDetail3");
      }else if(index == 1) {
        this.$router.push("/newsDetail2");
      }else if(index == 2) {
        this.$router.push("/newsDetail");
      }else{
        this.$router.push("/newsDetail1");
      }
    }
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 595px;
    background-image: url("../assets/news_bg1.png");
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 67px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .btn-item:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: 917px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../assets/news_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .news-item-top {
      width: 100%;
      height: 50%;
      background-image: url("../assets/news_detail2_image2.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-size: contain;
      background-position: center;
      background-color: #ffffff;
      border-bottom: 1px solid #EEEEEE;
    }
    .news-item-top1 {
      width: 100%;
      height: 50%;
      background-image: url("../assets/new-pic02.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .news-item-top2 {
      width: 100%;
      height: 50%;
      background-image: url("../assets/news_detail_image1.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .news-item-top3 {
      width: 100%;
      height: 50%;
      background-image: url("../assets/news_list_jzd.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .about-page3 {
    width: 100%;
    height: 1146px;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/news_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    .bottom-news-item {
      width: 30%;
      height: 42%;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: #ffffff;
      .bottom-news-date {
        font-family: SegoeUI;
        font-size: 24px;
        color: #666666;
        line-height: 66px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
      }
      .bottom-news-title {
        font-family: MicrosoftYaHei;
        font-size: 19px;
        color: #0b1030;
        line-height: 29px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -8%;
      }
      .bottom-news-content {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #777985;
        line-height: 26px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -7%;
      }
    }
  }
  .about-page4 {
    width: 100%;
    height: 1153px;
    display: flex;
    background-image: url("../assets/about_bg4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
