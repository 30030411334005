import { createStore } from 'vuex'
import tab from './tabs'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tab
  }
})
