<template>
  <div class="container">
    <div class="background-layer1"></div>
    <div class="background-layer2"></div>
    <a-card class="card" :bordered="false" style="border-radius: 8px;">
        <a-form
          :model="form"
          :style="{ width: '360px' }"
          style="margin-left: -30px;"
          @submit="submit"
          class="formContainer"
        >
          <span class="title">密码登录</span>
          <a-form-item
            field="name"
          >
            <a-input
              v-model="form.name"
              placeholder="请输入用户名"
              default-value="13910718125"
            />
          </a-form-item>
          <a-form-item field="password">
            <a-input-password
              v-model="form.password"
              placeholder="请输入密码"
              
            />
          </a-form-item>
          <span class="forget">忘记密码</span>
          <a-form-item>
            <a-button html-type="submit" class="login-btn" type="primary">登录</a-button>
          </a-form-item>
        </a-form>
    </a-card>
    <img src="../assets/logo-fy.png" alt="" class="logo" width="237px" />
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { userLogin } from "../api";
export default {
  name: "",
  data() {
    return {
      response: null,
      form: {
        name: "",
        password: "",
      },
    };
  },
  created() {},
  methods: {
    submit() {
      userLogin(this.form).then((data) => {
        if(data.data.data.code === 200) {
          const userInfo = data.data.data
          localStorage.setItem('userInfo',JSON.stringify(userInfo))
          this.$router.push('/home')
        }else {
          console.log(data.data.data.message)
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;

  .background-layer1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/login-bg1.png'); /* 第一张背景图片的路径 */
    background-repeat: no-repeat;
    background-size: cover; /* 根据需要调整背景大小 */
    z-index: 1; /* 控制图层叠加顺序 */
  }

  .background-layer2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/login-bg.png'); /* 第二张背景图片的路径 */
    background-repeat: no-repeat;
    background-size: cover; /* 根据需要调整背景大小 */
    z-index: 2; /* 控制图层叠加顺序 */
  }
  .topTitles {
    z-index: 3;
    right: 193px;
    width: 400px;
    top: 56px;
    height: 300px;
    position: relative;
  }
  .logo {
    left: 73px;
    top: 59px;
    width: 237px;
    height: 55px;
    z-index: 3;
    position: relative;
  }
  .card {
    position: relative;
    z-index: 3;
    background-image: url(../assets/login-form-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 400px;
    position: absolute;
    right: 193px;
    bottom: 165px;
    height: 400px;
    .formContainer {
      padding-top: 20px;
      .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        color: #131313;
        margin-left: 170px;
        margin-bottom: 50px;
      }
      .login-btn {
        width: 360px;
        margin-top: 20px;
      }
      .input-name {
        margin-top: 30px;
      }
      .forget {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #444444;
        line-height: 48px;
        margin-left: 290px;
        margin-top: -20px;
      }
    }
  }
}
</style>
