<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-page2">
      <!-- 左边内容 新闻详情 -->
      <div style="width: 70%; height: 100%; border-right: 1px solid #eeeeee">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 16%;
            display: flex;
            flex-direction: column;
            padding-top: 3%;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 40px;
              color: #3d434b;
              line-height: 63px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            "
            >我司商标注册申请已初步审定并公告</span
          >
          <div class="news-title-icon"></div>
          <span
            style="
              font-family: HarmonyOS_Sans_Medium;
              font-size: 20px;
              color: #3d434b;
              line-height: 63px;
              margin-top: 30px;
            "
            >2024-05-20 10:08:19</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 10px;
              text-indent: 30px;
            "
            >热烈祝贺我司商标注册申请已于2024年5月20日初步审定并公告（第1888期《商标公告》），这是我司品牌发展历程中的重要里程碑，标志着我们的品牌保护取得了重要进展。</span
          >
          <img
            style="width: 100%; height: auto;margin-top: 30px"
            src="../assets/news_detail2_image2.png"
            alt=""
          />
          
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >商标是知识产权的重要组成部分，是企业的无形资产。自公司成立以来，我们一直高度重视品牌建设和知识产权保护工作。商标的成功初审确保了我们的品牌独特性和合法性，使该商标能够得到国家强制力的保护，而且对丰富我司品牌，维护企业名称，扩大企业影响，都具有积极的意义。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >我们会更加努力来维护品牌的信誉，不断提高客户对我们品牌的满意度，从而使商标的价值不断提升。秉承我司“通过技术创新和卓越服务，让企业业务简单、便捷、协同和智能。”的企业使命，为客户创造价值，推动企业数智化转型和业务创新！</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
            "
            >附初审公告通知</span
          >
          <img
            style="width: 100%; height: auto;margin-top: 30px;margin-bottom: 80px;"
            src="../assets/news_detail3_image1.png"
            alt=""
          />
        </div>
        <!-- 底部返回列表 -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 78%;
            margin-left: 16%;
            padding-bottom: 80px;
            border-top: 1px solid #eeeeee;
            padding-top: 60px;
          "
        >
          <div style="display: flex; flex-direction: column">
            <div
              style="display: flex; padding-bottom: 10px"
              v-for="(item, index) in newsList"
              :key="index"
              @click="jumpOtherNews(index)"
            >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #666666;
                "
                >{{ item.newsOrder }}</span
              >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #999999;
                "
                >{{ item.newsTitle }}</span
              >
            </div>
          </div>
          <img
            style="width: 191px; height: 60px"
            src="../assets/news_detail_return.png"
            alt=""
            @click="backToList"
          />
        </div>
      </div>
      <!-- 右边内容 推荐新闻 -->
      <div style="width: 30%; height: 100%">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 10%;
            padding-top: 9%;
            display: flex;
            flex-direction: column;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 28px;
              color: #333333;
            "
            >推荐新闻</span
          >

          <div
            style="display: flex; flex-direction: column; padding-top: 7%"
            v-for="(item, index) in newsListRight"
            :key="index"
            @click="jumpOtherNewsRight(index)"
          >
            <span
              style="
                font-family: HarmonyOS_Sans_Medium;
                font-size: 16px;
                color: #333333;
              "
              >{{ item.newsDate }}</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                margin-top: 3%;
                line-height: 29px;
                width: 65%;
              "
              >{{ item.newsTitle }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      newsList: [
        {
          newsDate: "2023.09.04",
          newsTitle:
            "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2024.04.21",
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          newsOrder: "下一篇：",
        },
      ],
      newsListRight: [
        {
          newsDate: "2024.04.22",
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.12.05",
          newsTitle:
            "丰樾科技携手中国长城，为信创领军企业数字化转型添砖加瓦",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.09.04",
          newsTitle:
            "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          newsOrder: "下一篇：",
        },
      ],
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    jumpOtherNews(index) {
      if(index == 0) {
        this.$router.push("/newsDetail1");
      }else{
        this.$router.push("/newsDetail2");
      }
    },
    jumpOtherNewsRight(index) {
      if(index == 0) {
        this.$router.push("/newsDetail2");
      }else if(index == 1) {
        this.$router.push("/newsDetail");
      }else{
        this.$router.push("/newsDetail1");
      }
    },
    backToList() {
      this.$router.push("/news");
    }
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 142px;
    background: linear-gradient(to right, #00aaff, #1758d8);
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 45px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: auto;
    display: flex;
    background-image: url("../assets/news_detail_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .news-title-icon {
      width: 22px;
      height: 4px;
      background: linear-gradient(to right, #00aaff, #1758d8);
      margin-top: 20px;
    }
  }
  .about-page3 {
    width: 100%;
    height: 1146px;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/news_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    .bottom-news-item {
      width: 30%;
      height: 42%;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: #ffffff;
      .bottom-news-date {
        font-family: SegoeUI;
        font-size: 24px;
        color: #666666;
        line-height: 66px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
      }
      .bottom-news-title {
        font-family: MicrosoftYaHei;
        font-size: 19px;
        color: #0b1030;
        line-height: 29px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -8%;
      }
      .bottom-news-content {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #777985;
        line-height: 26px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -7%;
      }
    }
  }
  .about-page4 {
    width: 100%;
    height: 1153px;
    display: flex;
    background-image: url("../assets/about_bg4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
