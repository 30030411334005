<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
      <div
        class="page1-middle"
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 54px;
          color: #ffffff;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 90px;
        "
      >
        <span>产品中心</span>
      </div>
      <div class="page1-bottom">
        <div class="btn-item" @click="changePageNo(2)">致知平台</div>
        <div class="btn-item" @click="changePageNo(1)">智服云</div>
        <div class="btn-item" @click="changePageNo(3)">樾畅销</div>
        <div class="btn-item" @click="changePageNo(4)">樾资产</div>
      </div>
    </div>
    <!-- 第二页 智服云服务 -->
    <div class="about-page2" v-show="showPageNo == 1">
      <div
        style="
          width: 72%;
          height: 65%;
          background-color: #ffffff;
          border-radius: 0 0 80px 0;
          display: flex;
        "
      >
        <div class="service-left"></div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 58%;
            height: 100%;
          "
        >
          <div
            style="
              height: 60%;
              width: 84%;
              margin-left: 8%;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              style="
                padding-bottom: 15px;
                border-bottom: 1px solid #eeeeee;
                margin-top: 12%;
              "
            >
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 42px;
                  color: #0b1030;
                  line-height: 58px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >智服云</span
              >
            </div>
            <span
              style="
                font-family: MicrosoftYaHei;
                font-size: 18px;
                color: #999999;
                line-height: 31px;
                text-align: justifyLeft;
                font-style: normal;
                text-transform: none;
                margin-top: 6%;
              "
              >智服云是丰樾科技面向大中型企业售后服务领域提供的基于自主知识产权低代码平台研发的应用系统。丰樾智服云通过对售后业务服务受理、智能派工、现场服务、服务结算、服务商、客户、备件等全流程进行一站式管理，打造标准化、数字化、智能化的售后服务体系。该系统借助
              AI、IOT、AR技术实现智能化服务、预测性服务，助力企业服务数字化转型，提升服务效率、客户体验和客户价值。</span
            >
          </div>
          <div
            style="
              width: 84%;
              margin-left: 8%;
              height: 40%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
          >
            <img
              style="width: 179px; height: 137px; margin-right: 20px"
              src="../assets/service_01bg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="about-page3" v-show="showPageNo == 2">
      <div
        style="
          width: 72%;
          height: 65%;
          background-color: #ffffff;
          border-radius: 0 0 80px 0;
          display: flex;
        "
      >
        <div class="service-left"></div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 58%;
            height: 100%;
          "
        >
          <div
            style="
              height: 60%;
              width: 84%;
              margin-left: 8%;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              style="
                padding-bottom: 15px;
                border-bottom: 1px solid #eeeeee;
                margin-top: 12%;
              "
            >
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 42px;
                  color: #0b1030;
                  line-height: 58px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >致知平台</span
              >
            </div>
            <span
              style="
                font-family: MicrosoftYaHei;
                font-size: 18px;
                color: #999999;
                line-height: 31px;
                text-align: justifyLeft;
                font-style: normal;
                text-transform: none;
                margin-top: 6%;
              "
              >致知平台（ZZPlat）是一种基于元数据驱动的全模型的低代码平台，可以帮助企业快速构建成熟可靠、高并发、可拓展的业务应用系统。致知平台采用了微内核、数据总线、插件体系的核心设计思想，具有高度的可配置性和可扩展性，能够快速满足不同业务场景的需求。通过使用致知平台，开发人员可以快速搭建系统框架、定义元数据、配置业务流程、开发业务模块、进行系统集成和测试等操作，从而提高开发效率和质量。同时，致知平台还具有良好的安全性和稳定性，能够保障企业应用系统的可靠运行。</span
            >
          </div>
          <div
            style="
              width: 84%;
              margin-left: 8%;
              height: 40%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
          >
            <img
              style="width: 179px; height: 137px; margin-right: 20px"
              src="../assets/service_01bg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="about-page4" v-show="showPageNo == 3">
      <div
        style="
          width: 72%;
          height: 65%;
          background-color: #ffffff;
          border-radius: 0 0 80px 0;
          display: flex;
        "
      >
        <div class="service-left"></div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 58%;
            height: 100%;
          "
        >
          <div
            style="
              height: 60%;
              width: 84%;
              margin-left: 8%;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              style="
                padding-bottom: 15px;
                border-bottom: 1px solid #eeeeee;
                margin-top: 12%;
              "
            >
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 42px;
                  color: #0b1030;
                  line-height: 58px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >樾畅销</span
              >
            </div>
            <span
              style="
                font-family: MicrosoftYaHei;
                font-size: 18px;
                color: #999999;
                line-height: 31px;
                text-align: justifyLeft;
                font-style: normal;
                text-transform: none;
                margin-top: 6%;
              "
              >樾畅销（CRM）是丰樾科技面向大中型企业销售管理领域提供的基于自主知识产权低代码平台研发的应用系统。丰樾樾畅销（CRM）帮助企业实现市场营销活动、挖掘潜在商机、销售管理、合同签订、回款追踪、深度经营等一系列售前、售中服务过程管理；提高客户满意度、增强客户忠诚度、优化销售流程、提高市场营销效果，并最终实现客户复购、业务持续增长的销售闭环管理系统。</span
            >
          </div>
          <div
            style="
              width: 84%;
              margin-left: 8%;
              height: 40%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
          >
            <img
              style="width: 179px; height: 137px; margin-right: 20px"
              src="../assets/service_01bg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="about-page5" v-show="showPageNo == 4">
      <div
        style="
          width: 72%;
          height: 65%;
          background-color: #ffffff;
          border-radius: 0 0 80px 0;
          display: flex;
        "
      >
        <div class="service-left"></div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 58%;
            height: 100%;
          "
        >
          <div
            style="
              height: 60%;
              width: 84%;
              margin-left: 8%;
              display: flex;
              flex-direction: column;
            "
          >
            <div
              style="
                padding-bottom: 15px;
                border-bottom: 1px solid #eeeeee;
                margin-top: 12%;
              "
            >
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 42px;
                  color: #0b1030;
                  line-height: 58px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >樾资产</span
              >
            </div>
            <span
              style="
                font-family: MicrosoftYaHei;
                font-size: 18px;
                color: #999999;
                line-height: 31px;
                text-align: justifyLeft;
                font-style: normal;
                text-transform: none;
                margin-top: 6%;
              "
              >对房产来源、权属、图片类等相关资料需进行统一的电子档案管理；
系统建设需考虑在资产台账登记表以不同维度进行统计，以满足公司统一标准、统一口径、摸清资产家底、保值增值的管理要求；
业务范围覆盖：经营性资产、非经营性资产、办公用房、承租经营管理房产、监（托）管资产等。满足资产台账基础数据的准确性。
实现覆盖招商管理、租赁管理、运营服务、物业管理、工单管理、业财一体化管理、商城管理、移动端、统计分析等各环节的资产经营全业务流程标准化、线上化管理。
</span
            >
          </div>
          <div
            style="
              width: 84%;
              margin-left: 8%;
              height: 40%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
          >
            <img
              style="width: 179px; height: 137px; margin-right: 20px"
              src="../assets/service_01bg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
        showPageNo : 1
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    changePageNo(num) {
        this.showPageNo = num
    }
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 600px;
    background-image: url("../assets/service_bg1.png");
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 67px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .btn-item:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: 911px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/service_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .service-left {
      width: 42%;
      height: 100%;
      background-image: url("../assets/service_prd1.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .about-page3 {
    width: 100%;
    height: 911px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/service_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .service-left {
      width: 42%;
      height: 100%;
      background-image: url("../assets/service_zzpt.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .about-page4 {
    width: 100%;
    height: 911px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/service_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .service-left {
      width: 42%;
      height: 100%;
      background-image: url("../assets/new-pic01.png");
      background-size: contain;
      background-position: center;
    }
  }
  .about-page5 {
    width: 100%;
    height: 911px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/service_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .service-left {
      width: 42%;
      height: 100%;
      background-image: url("../assets/service_prd4.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>
