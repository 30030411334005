<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-page2">
      <!-- 左边内容 新闻详情 -->
      <div style="width: 70%; height: 100%; border-right: 1px solid #eeeeee">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 16%;
            display: flex;
            flex-direction: column;
            padding-top: 3%;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 40px;
              color: #3d434b;
              line-height: 63px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            "
            >丰樾科技携手中国长城，为信创领军企业数字化转型添砖加瓦</span
          >
          <div class="news-title-icon"></div>
          <span
            style="
              font-family: HarmonyOS_Sans_Medium;
              font-size: 20px;
              color: #3d434b;
              line-height: 63px;
              margin-top: 30px;
            "
            >2023-12-05 10:15:58</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 10px;
              text-indent: 30px;
            "
            >近日，北京丰樾科技有限公司（以下简称：丰樾科技）签约中国长城科技集团股份有限公司（股票代码：000066，简称：中国长城））售后服务管理系统，双方将展开深入合作，持续推进中国长城服务数字化转型。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 800;
              font-size: 18px;
              color: #3d434b;
              margin-top: 30px;
            "
            >中国长城：我国自主计算产业的开拓者和引领者</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >中国长城科技集团股份有限公司（股票代码：000066，简称中国长城）成立于1986年，是中国电子信息产业集团有限公司（简称：中国电子）旗下计算产业的主力军，是我国自主计算产业的开拓者和引领者。中国长城曾研发出我国第一台具有自主知识产权的中文微型电脑、第一块电脑硬盘、第一款终端ASIC芯片、第一台显示器、第一台光纤转换器、第一台光笔图形显示终端等，是我国电脑、电源、高新电子、金融信息化、医疗信息化等领域系列国家和行业标准的起草单位之一。目前，中国长城旗下拥有90家控股、参股企业，1.67万员工，总资产331.6亿元，拥有多个国家技术创新示范企业、高新技术企业、自主创新行业龙头企业、湖南商用密码产业示范基地等荣誉资质，4个国家级创新平台，专利总数1300余项，科技人员数量占比超30%。网信整机市场占有率第一、PC电源、服务器电源、区块链电源国内市场占有率第一。</span
          >
          <img
            style="width: 100%; height: auto"
            src="../assets/news_detail_image1.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 800;
              font-size: 18px;
              color: #3d434b;
              margin-top: 30px;
            "
            >项目背景：</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >随着信创领域竞争白热化，很多产品趋于同质化，市场竞争格局由产品转向了服务。随着客户对服务体验的要求提升，后市场服务能力是当前信创领域的竞争焦点。随着中国长城业务的发展，服务客户数的激增，对服务的协同效率提出了更高的要求。然而中国长城现有各类系统烟囱式部署，数据以及流程的割裂导致协同效率低下；数量庞大的售后工程师的服务数据无法直观掌握；客户服务接入单一，产品以及客户信息闭塞，无法形成直观的数据展示；服务经营过程中对备件的精细化管理要求让服务数字化转型的需求更加凸显；而针对全国近2000家服务商的管理与技术支持等，也是中国长城不可忽视的痛点。</span
          >
          <img
            style="width: 100%; height: auto; margin-top: 20px"
            src="../assets/news_detail_image2.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 800;
              font-size: 18px;
              color: #3d434b;
              margin-top: 30px;
            "
            >全面布局，开启服务数字化转型新篇章</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >为了解决目前困扰，中国长城决定重构服务信息化管理体系。前期经过大量的市场调研、解决方案以及产品对比等多方面考察，中国长城最终在近20家行业内专业信息化厂商中选择丰樾科技，共同构建中国长城售后服务数字化管理平台，通过系统实现全流程可视化的服务过程管理，打造卓越的客户体验，优化自营和第三方的服务商管理体系，赋能产品管理，开启服务数字化转型新篇章。</span
          >
          <img
            style="width: 100%; height: auto; margin-top: 20px"
            src="../assets/news_detail_image3.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >丰樾科技将为中国长城打造的数智化智能服务平台，将助力中国长城实现以下提升：</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >01 客户体验提升</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >通过小程序、官网等多种方式，客户可自助进行售后服务的发起，全程参与互动，实时了解自己的服务工单进度和状态，并自主参与服务评价打分，体验感得到显著提升。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >02 协同效率提升</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >中国长城售后服务数字化管理平台解决了现有售后工作各个板块系统烟囱式部署、流程以及数据割裂的问题，各部门实时信息协同，数据实时同步，服务过程可跟踪可追溯，极大的提高了服务工作效率。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >03 备件科学管控</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >中国长城售后服务数字化管理平台配备完善的备件计划、备件管理流程，联拨调动，出库入库，回收/归还预警提示等。实现了备件的可视化管理，确保设备维修和维护的及时进行、优化设备维修成本、提高设备可靠性以及降低企业运营成本。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >04 赋能产品管理</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >中国长城售后服务数字化管理平台配完善的数据看板，针对产品的故障类型进行数据分析和实时展示，产品故障以及服务情况一目了然，助力产品质量提升。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >05 支撑绩效考核</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >中国长城售后服务数字化管理平台对客户服务各类指标进行考核跟踪，服务人员的工作状态，工单及时率，以周为单位针对工程师团队的服务状态进行滚动展示，每条工单实时反映客户满意度评价信息，为绩效考核提供强有力的数据支撑。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >06 保障服务运营</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >针对全国近2000家服务网点以及服务机构，中国长城售后服务数字化管理平台在服务商的入驻、考核以及结算等方面配备了完善的管理流程，保障服务的高效运营。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 800;
              font-size: 18px;
              color: #3d434b;
              margin-top: 30px;
            "
            >关于丰樾科技</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
              margin-bottom: 80px;
            "
            >北京丰樾科技有限公司致力于通过云、AI 、大数据、loT 、AR、5G
            等新一代技术服务企业数智化，基于低代码开发平台（丰樾致知平台）打造新一代创新业务云平台和现场服务管理(FSM)
            系统，以及客户关系管理（CRM）系统，让业务创新简单、便捷、协同和智能。</span
          >
        </div>
        <!-- 底部返回列表 -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 78%;
            margin-left: 16%;
            padding-bottom: 80px;
            border-top: 1px solid #eeeeee;
            padding-top: 60px;
          "
        >
          <div style="display: flex; flex-direction: column">
            <div
              style="display: flex; padding-bottom: 10px"
              v-for="(item, index) in newsList"
              :key="index"
              @click="jumpOtherNews(index)"
            >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #666666;
                "
                >{{ item.newsOrder }}</span
              >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #999999;
                "
                >{{ item.newsTitle }}</span
              >
            </div>
          </div>
          <img
            style="width: 191px; height: 60px"
            src="../assets/news_detail_return.png"
            alt=""
            @click="backToList"
          />
        </div>
      </div>
      <!-- 右边内容 推荐新闻 -->
      <div style="width: 30%; height: 100%">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 10%;
            padding-top: 9%;
            display: flex;
            flex-direction: column;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 28px;
              color: #333333;
            "
            >推荐新闻</span
          >

          <div
            style="display: flex; flex-direction: column; padding-top: 7%"
            v-for="(item, index) in newsListRight"
            :key="index"
            @click="jumpOtherNewsRight(index)"
          >
            <span
              style="
                font-family: HarmonyOS_Sans_Medium;
                font-size: 16px;
                color: #333333;
              "
              >{{ item.newsDate }}</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                margin-top: 3%;
                line-height: 29px;
                width: 65%;
              "
              >{{ item.newsTitle }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      newsList: [
        {
          newsDate: "2024.04.22",
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.09.04",
          newsTitle:
            "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          newsOrder: "下一篇：",
        },
      ],
      newsListRight: [
        {
          newsDate: "2024.05.21",
          newsTitle: "我司商标注册申请已初步审定并公告",
          newsOrder: "下一篇：",
        },
        {
          newsDate: "2024.04.22",
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.09.04",
          newsTitle:
            "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          newsOrder: "下一篇：",
        },
      ],
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    jumpOtherNews(index) {
      if(index == 0) {
        this.$router.push("/newsDetail2");
      }else{
        this.$router.push("/newsDetail1");
      }
    },
    jumpOtherNewsRight(index) {
      if(index == 0) {
        this.$router.push("/newsDetail3");
      }else if(index == 1) {
        this.$router.push("/newsDetail2");
      }else{
        this.$router.push("/newsDetail1");
      }
    },
    backToList() {
      this.$router.push("/news");
    },
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 142px;
    background: linear-gradient(to right, #00aaff, #1758d8);
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 45px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: auto;
    display: flex;
    background-image: url("../assets/news_detail_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .news-title-icon {
      width: 22px;
      height: 4px;
      background: linear-gradient(to right, #00aaff, #1758d8);
      margin-top: 20px;
    }
  }
  .about-page3 {
    width: 100%;
    height: 1146px;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/news_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    .bottom-news-item {
      width: 30%;
      height: 42%;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: #ffffff;
      .bottom-news-date {
        font-family: SegoeUI;
        font-size: 24px;
        color: #666666;
        line-height: 66px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
      }
      .bottom-news-title {
        font-family: MicrosoftYaHei;
        font-size: 19px;
        color: #0b1030;
        line-height: 29px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -8%;
      }
      .bottom-news-content {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #777985;
        line-height: 26px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -7%;
      }
    }
  }
  .about-page4 {
    width: 100%;
    height: 1153px;
    display: flex;
    background-image: url("../assets/about_bg4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
