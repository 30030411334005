<template>
  <div class="contentcontainer">
    <!-- 第一页 -->
    <div class="background-layer1">
      <div class="content">
        <div class="contentHeader">
          <div class="headerLeft"></div>
          <div class="headerMiddle">
            <a-button type="text">首页</a-button>
            <a-button type="text" @click="jumpToAbout">关于我们</a-button>
            <a-button type="text" @click="jumpToService">产品中心</a-button>
            <a-button type="text">技术支持</a-button>
            <a-button type="text" @click="jumpToNews">新闻中心</a-button>
            <a-button type="text" @click="jumpToContact">联系我们</a-button>
          </div>
          <div class="headerRight">
            <div class="rightSub" style="margin-right: 10px">
              <div class="rightSearch"></div>
            </div>
            <div class="rightSub">
              <span style="color: white; font-size: 16px">EN</span>
            </div>
          </div>
        </div>
        <div class="page1-middle">
          <img
            style="width: 493px; height: 76px"
            src="../assets/fengyue.png"
            alt=""
          />
          <span
            style="
              color: white;
              font-size: 48px;
              font-weight: 500;
              margin-top: -80px;
            "
            >自主知识产权低代码开发平台</span
          >
          <img
            style="width: 200px; height: 60px"
            src="../assets/home-page2-more.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 第二页 -->
    <div class="background-layer2">
      <div class="page2-content">
        <div class="page2-top">
          <span class="page2-top-title">公司介绍</span>
          <div class="page2-tab"></div>
        </div>
        <div class="page2-middle">
          <span class="page2-middle-cpn">丰樾科技</span>
          <span class="page2-middle-subtitle"
            >通过技术创新和卓越服务，让企业业务简单、便捷、协同和智能。</span
          >
          <span class="page2-middle-desc"
            >北京丰樾科技有限公司(简称:“丰樾科技")，致力于通过云计算、A、大数据、I0T、AR、5G等<br />新一代技术服务企业数智化，基于自主知识产权低代码开发平台...</span
          >
        </div>
        <div class="page2-bottom">
          <div class="page2-bottom-item">
            <span class="page2-bottom-title">央企</span>
            <div class="page2-bottom-content">
              <span class="page2-bottom-content-count">50</span>
              <span class="page2-bottom-content-more">+</span>
            </div>
          </div>
          <div class="page2-bottom-item">
            <span class="page2-bottom-title">地方国企</span>
            <div class="page2-bottom-content">
              <span class="page2-bottom-content-count">100</span>
              <span class="page2-bottom-content-more">+</span>
            </div>
          </div>
          <div class="page2-bottom-item">
            <span class="page2-bottom-title">大型民企</span>
            <div class="page2-bottom-content">
              <span class="page2-bottom-content-count">100</span>
              <span class="page2-bottom-content-more">+</span>
            </div>
          </div>
        </div>
        <div class="page2-end-btn"></div>
      </div>
    </div>
    <!-- 第三页 -->
    <div class="background-layer3">
      <div class="page3-container">
        <div class="page3-content">
          <div class="page3-left" @click="jumpToService">
            <div class="page3-split-line"></div>
            <div class="page3-middle-content" style="width: 100%; height: 100%">
              <div
                class="page3-middle-header"
                style="
                  width: 100%;
                  height: 31%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div class="page3-middle-header-title"></div>
                <div class="page3-middle-tab"></div>
              </div>
              <div
                class="page3-middle-bottom-content"
                style="width: 100%; height: 69%"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 30%;
                    margin-top: 33%;
                    justify-content: space-between;
                  "
                >
                  <div class="page3-middle-image"></div>
                  <span class="page3-middle-title">致知平台</span>
                  <div class="page3-middle-more"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="page3-middle" @click="jumpToService">
            <div class="page3-split-line"></div>
            <div class="page3-middle-content" style="width: 100%; height: 100%">
              <div
                class="page3-middle-header"
                style="
                  width: 100%;
                  height: 31%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div class="page3-middle-header-title">产品中心</div>
                <div class="page3-middle-tab"></div>
              </div>
              <div
                class="page3-middle-bottom-content"
                style="width: 100%; height: 69%"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 30%;
                    margin-top: 33%;
                    justify-content: space-between;
                  "
                >
                  <div class="page3-middle-image"></div>
                  <span class="page3-middle-title">智服云</span>
                  <div class="page3-middle-more"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="page3-right" @click="jumpToService">
            <!-- <div class="page3-split-line"></div> -->
            <div class="page3-middle-content" style="width: 100%; height: 100%">
              <div
                class="page3-middle-header"
                style="
                  width: 100%;
                  height: 31%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <div class="page3-middle-header-title"></div>
                <div class="page3-middle-tab"></div>
              </div>
              <div
                class="page3-middle-bottom-content"
                style="width: 100%; height: 69%"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 30%;
                    margin-top: 33%;
                    justify-content: space-between;
                  "
                >
                  <div class="page3-middle-image"></div>
                  <span class="page3-middle-title">樾畅销</span>
                  <div class="page3-middle-more"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四页 -->
    <div class="background-layer4">
      <div class="page4-top">
        <span class="page4-title">最新动态</span>
        <div class="page4-image-tab"></div>
      </div>
      <div class="page4-bottom">
        <a-row class="page4-grid" style="height: 100%">
          <a-col :span="12" style="height: 100%">
            <div class="page4-bottom-left" style="height: 100%">
              <div class="page4-left-bg" @click="jumpToDetail(3)">
                <div class="news-content">
                  <span class="news-date">03-15</span>
                  <span class="news-title"
                    >丰樾科技携手中国长城，为信创领军企业数字化转型添砖加瓦</span
                  >
                  <span class="news-subtitle"
                    >近日，北京丰樾科技有限公司（以下简称：丰樾科技）签约中国长城科技集团股份有限公司...</span
                  >
                </div>
                <div class="news-image"></div>
              </div>
            </div>
          </a-col>
          <a-col :span="12" style="height: 100%">
            <div class="page4-bottom-right">
              <div
                class="page4-list-item"
                v-for="(item, index) in newsData"
                :key="index"
                @click="jumpToDetail(index)"
              >
                <div class="news-list-top">
                  <div class="news-top-left">
                    <div class="news-list-point"></div>
                    <span class="news-list-title">{{ item.newsTitle }}</span>
                  </div>
                  <span class="news-list-date">{{ item.newsDate }}</span>
                </div>
                <div class="news-list-bottom">
                  <span class="news-list-subTitle">{{ item.subTitle }}</span>
                  <img
                    class="news-list-right-more"
                    src="../assets/page4-news-more-normal.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 第五页 -->
    <div class="background-layer5">
      <div class="page5-top">
        <span class="page5-title">合作伙伴</span>
      </div>
      <div class="page5-logos">
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_01.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_02.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_03.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_04.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_05.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_06.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_07.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_08.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_09.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_10.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_11.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_12.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import {} from "../api";
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";

export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      newsData: [
        {
          newsTitle: "我司商标注册申请已初步审定并公告",
          subTitle:
            "热烈祝贺我司商标注册申请已于2024年5月20日初步审定并公告（第1888期《商标公告》）...",
          newsDate: "05-21",
          imagePath: "../assets/page4-news-more-normal.png",
        },
        {
          newsTitle: "重塑品牌标识：我们新的公司Logo诞生了",
          subTitle:
            "我们很高兴地向大家宣布，我们的公司Logo已经完成了全新的设计和重塑。这一变革是我们品牌发展...",
          newsDate: "04-22",
          imagePath: "../assets/page4-news-more-normal.png",
        },
        {
          newsTitle: "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          subTitle:
            "2023年9月4日，集智达&丰樾AMS(售后服务)系统项目启动会在北京集智达公司总部成功举行...",
          newsDate: "09-04",
          imagePath: "../assets/page4-news-more-select.png",
        },
      ],
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToServiceWithIndex(index) {
      console.log(index)
      // this.$router.push({ name: 'Service', params: { pages: '111' } });
    },
    jumpToDetail(index) {
      if(index == 0) {
        this.$router.push("/newsDetail3");
      }else if(index == 1) {
        this.$router.push("/newsDetail2");
      }else if(index == 2) {
        this.$router.push("/newsDetail1");
      }else{
        this.$router.push("/newsDetail");
      }
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.contentcontainer {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .background-layer1 {
    width: 100%;
    height: 100%;
    background-image: url("../assets/home_bg1.png");
    background-clip: content-box;
    background-position: center center;
  }
  .content {
    height: 100%;
    z-index: 2;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 67px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-middle {
      display: flex;
      flex-direction: column;
      height: 40%;
      margin-top: 10%;
      margin-left: 153px;
      justify-content: space-between;
    }
  }

  .background-layer2 {
    width: 100%;
    height: 100%;
    background-image: url("../assets/home_bg2.jpg");
    background-clip: content-box;
    background-position: center center;
    .page2-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 11%;
      padding-top: 8%;
      .page2-top {
        .page2-top-title {
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 50px;
          color: #333333;
          line-height: 76px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: -6px;
        }
        .page2-tab {
          width: 43px;
          height: 8px;
          margin-top: 5px;
          background-image: url("../assets/home-page2-tab.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .page2-middle {
        margin-top: 6%;
        display: flex;
        flex-direction: column;
        .page2-middle-cpn {
          font-family: MicrosoftYaHei;
          font-size: 32px;
          color: #333333;
          line-height: 32px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .page2-middle-subtitle {
          margin-top: 4%;
          height: 23px;
          font-family: MicrosoftYaHei;
          font-size: 22px;
          color: #333333;
          line-height: 32px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .page2-middle-desc {
          margin-top: 2%;
          width: 664px;
          height: 46px;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 30px;
          text-align: justifyLeft;
          font-style: normal;
          text-transform: none;
        }
      }
      .page2-bottom {
        width: 486px;
        display: flex;
        margin-top: 4%;
        margin-bottom: 3%;
        justify-content: space-between;
        .page2-bottom-item {
          .page2-bottom-title {
            width: 37px;
            height: 17px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
            font-size: 18px;
            color: #888888;
            line-height: 28px;
            text-align: justifyLeft;
            font-style: normal;
            text-transform: none;
          }
          .page2-bottom-content {
            margin-top: 14%;
            .page2-bottom-content-count {
              width: 60px;
              height: 42px;
              font-family: OPPOSans, OPPOSans;
              font-weight: normal;
              font-size: 48px;
              color: #00aaff;
              line-height: 28px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
            }
            .page2-bottom-content-more {
              width: 11px;
              height: 12px;
              margin-left: 1%;
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 22px;
              color: #888888;
              line-height: 28px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
            }
          }
        }
      }
      .page2-end-btn {
        width: 200px;
        height: 60px;
        margin-top: 1%;
        background-image: url("../assets/home-page2-more.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .background-layer3 {
    width: 100%;
    height: 100%;
    background-image: url("../assets/home_bg3.jpg");
    background-clip: content-box;
    background-position: center center;
    .page3-container {
      width: 100%;
      height: 100%;
      padding-left: 209px;
      padding-right: 209px;
      .page3-content {
        display: flex;
        width: calc(100% - 418px);
        height: 100%;

        .page3-left {
          width: calc(100% / 3);
          height: 100%;
          display: flex;
          .page3-split-line {
            background-color: #ecf0f8;
            width: 1px;
            height: 100%;
            opacity: 0.15;
          }
          .page3-middle-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .page3-middle-header-title {
              height: 49px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 50px;
              color: #ffffff;
              line-height: 76px;
              text-align: center;
              font-style: normal;
              text-transform: none;
              margin-top: 15%;
              margin-bottom: 5%;
            }
            .page3-middle-tab {
              width: 43px;
              height: 8px;
              // background-image: url("../assets/home-page2-tab.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-image {
              width: 68px;
              height: 66px;
              background-image: url("../assets/home_page3_image1.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-title {
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 26px;
              color: #ecf0f8;
            }
            .page3-middle-more {
              width: 34px;
              height: 34px;
              background-image: url("../assets/home_page3_more.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }

        .page3-middle {
          width: calc(100% / 3);
          height: 100%;
          display: flex;
          .page3-split-line {
            background-color: #ecf0f8;
            width: 1px;
            height: 100%;
            opacity: 0.15;
          }
          .page3-middle-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .page3-middle-header-title {
              height: 49px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 50px;
              color: #ffffff;
              line-height: 76px;
              text-align: center;
              font-style: normal;
              text-transform: none;
              margin-top: 15%;
              margin-bottom: 5%;
            }
            .page3-middle-tab {
              width: 43px;
              height: 8px;
              background-image: url("../assets/home-page2-tab.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-image {
              width: 68px;
              height: 66px;
              background-image: url("../assets/home_page3_image2.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-title {
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 26px;
              color: #ecf0f8;
            }
            .page3-middle-more {
              width: 34px;
              height: 34px;
              background-image: url("../assets/home_page3_more.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        .page3-right {
          width: calc(100% / 3);
          height: 100%;
          display: flex;
          background-image: url("../assets/home-page3-right-bg.png");
          .page3-split-line {
            background-color: #ecf0f8;
            width: 1px;
            height: 100%;
            opacity: 0.15;
          }
          .page3-middle-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .page3-middle-header-title {
              height: 49px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 50px;
              color: #ffffff;
              line-height: 76px;
              text-align: center;
              font-style: normal;
              text-transform: none;
              margin-top: 15%;
              margin-bottom: 5%;
            }
            .page3-middle-tab {
              width: 43px;
              height: 8px;
              // background-image: url("../assets/home-page2-tab.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-image {
              width: 68px;
              height: 66px;
              background-image: url("../assets/home_page3_image3.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
            .page3-middle-title {
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 26px;
              color: #ecf0f8;
            }
            .page3-middle-more {
              width: 34px;
              height: 34px;
              background-image: url("../assets/home_page3_more.png");
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }
  }
  .background-layer4 {
    width: 100%;
    height: 100%;
    background-image: url("../assets/home_bg4.jpg");
    background-clip: content-box;
    background-position: center center;
    .page4-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 15%;
      .page4-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #333333;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 3%;
      }
      .page4-image-tab {
        width: 43px;
        height: 8px;
        margin-top: 5px;
        background-image: url("../assets/home-page2-tab.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 1%;
      }
    }
    .page4-bottom {
      width: 100%;
      height: 85%;
      .page4-left-bg {
        width: 70%;
        height: 60%;
        margin-left: 20%;
        margin-top: 21%;
        display: flex;
        flex-direction: column;
        background-image: url("../assets/home_page4_leftbg.png");
        background-repeat: no-repeat;
        background-size: cover;
        .news-content {
          width: 100%;
          height: 36%;
          display: flex;
          flex-direction: column;
          .news-date {
            width: 80%;
            margin-left: 10%;
            margin-top: 7%;
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 500;
            font-size: 24px;
            color: #ffffff;
            line-height: 29px;
            text-align: justifyLeft;
            font-style: normal;
            text-transform: none;
          }
          .news-title {
            width: 80%;
            margin-left: 10%;
            font-family: MicrosoftYaHei;
            font-size: 22px;
            color: #ffffff;
            line-height: 29px;
            text-align: justifyLeft;
            font-style: normal;
            text-transform: none;
            margin-top: 3%;
          }
          .news-subtitle {
            width: 80%;
            margin-left: 10%;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 18px;
            color: #ffffff;
            line-height: 29px;
            text-align: justifyLeft;
            font-style: normal;
            text-transform: none;
            margin-top: 3%;
          }
        }
        .news-image {
          width: 80%;
          height: 44%;
          margin-left: 10%;
          margin-top: 10%;
          display: flex;
          flex-direction: column;
          background-image: url("../assets/home-page4-newsimage.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .page4-bottom-right {
        height: 100%;
        margin-top: 21%;
        height: 60%;
        .page4-list-item {
          height: calc(100% / 3);
          width: 85%;
          display: flex;
          flex-direction: column;
          border-top: 2px solid #f4f4f4;
          .news-list-top {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 30px;
            .news-top-left {
              display: flex;
              align-items: center;
              width: 80%;
              .news-list-point {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: #00aaff;
                margin-left: 25px;
              }
              .news-list-title {
                font-family: MicrosoftYaHei;
                font-size: 22px;
                color: #333333;
                text-align: justifyLeft;
                margin-left: 15px;
              }
            }
            .news-list-date {
              font-family: PingFang-SC, PingFang-SC;
              font-weight: 500;
              font-size: 24px;
              color: #888888;
              line-height: 29px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
            }
          }
          .news-list-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2%;
            .news-list-subTitle {
              width: 70%;
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #888888;
              line-height: 29px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-left: 46px;
            }
            .news-list-right-more {
              width: 31px;
              height: 18px;
            }
          }
        }
      }
    }
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
