import Cookies from "js-cookie"

export default {
    state: {
        // 管理消息抽屉的显示
        drawer: false,
        menu: [],
        // 时间轴刷新
        timelineRefresh: false
    },
    mutations: {
        // 修改展开收起的方法
        changeState(state) {
            state.drawer = !state.drawer
        },
        refreshTimeLine(state) {
            state.timelineRefresh = !state.timelineRefresh
        }
    },
    setMenu(state,val) {
        state.menu = val
        // json序列化菜单数据
        Cookies.set('menu',JSON.stringify(val))
    },
    // 动态注册路由
    setRouter(state,router) {
        // 判断缓存中是否有数据
        if (!Cookies.get('menu')) return
        const menu = JSON.parse(Cookies.get('menu'))
        // 动态组装路由数据
        const menuArray = []
        menu.forEach(item => {
            if(item.children) {
                item.children = item.children.map(item => {
                    // 给map返回的新children的item添加component属性
                    item.component = () => import(`../views/${item.url}`)
                    // 这个return是map的??
                    return item
                })
                // 添加数组内容时使用... 相当于swift的append(contentof)
                menuArray.push(...item.children)
            }else{
                item.component = () => import(`../views/${item.url}`)
                menuArray.push(item)
            }
        });
        // 动态添加路由
        console.log(menuArray,'routes')
        menuArray.forEach(item => {
            router.addRoute('main',item)
        })
    }
}