<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
      <div
        class="page1-middle"  
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 54px;
          color: #ffffff;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 90px;
        "
      >
        <span>关于我们</span>
      </div>
      <div class="page1-bottom">
        <div class="btn-item" @click="changePageNo(1)">公司简介</div>
        <div class="btn-item" @click="changePageNo(2)">企业文化</div>
        <!-- <div class="btn-item">发展历程</div> -->
        <!-- <div class="btn-item">荣誉资质</div> -->
        <div class="btn-item" @click="changePageNo(3)">合作伙伴</div>
      </div>
    </div>
    <!-- 第二页 公司简介 -->
    <div class="about-page2" v-show="showPageNo == 1">
      <div
        style="
          height: 55%;
          width: 100%;
          margin-top: 10%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 14%;
        "
      >
        <span
          style="
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 42px;
            color: #0b1030;
            line-height: 58px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          "
          >公司简介</span
        >
        <span
          style="
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: bold;
            font-size: 26px;
            color: #333333;
            line-height: 61px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          "
          >北京丰樾科技有限公司</span
        >
        <span
          style="
            width: 45%;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            color: #666666;
            line-height: 32px;
            text-align: justifyLeft;
            font-style: normal;
            text-transform: none;
          "
          >北京丰樾科技有限公司（简称:“丰樾科技”），致力于通过云计算、AI
          、大数据、loT、AR、5G等新一代技术服务企业数智化，基于自主知识产权低代码开发平台（丰樾致知平台）打造新一代现场服务管理(FSM)、客户关系管理（CRM）以及企业级资产运营管理等业务应用系统，让企业业务简单、便捷、协同和智能。
          <br /><br />丰樾科技创始人及合伙人来自企业管理软件头部厂商，团队在企业服务领域有多年大中型企业核心业务整体规划、产品研发、客户经营等从业经验，直接服务过50+央企、地方国企、大型民营企业、银行等客户，深刻理解企业业务场景及数智化需求。丰樾科技拥有专业的产品研发、项目实施交付以及运维服务团队，同时具备完善的客户成功管理体系，为企业提供咨询、售前、实施、运维和持续优化保障服务，助力企业服务数字化转型，提升服务效率、客户体验和客户价值。</span
        >
      </div>
    </div>
    <!-- 第三页 企业文化 -->
    <div class="about-page3" v-show="showPageNo == 2">
      <div
        style="
          width: 100%;
          height: 210px;
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 42px;
          color: #0b1030;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        "
      >
        企业文化
      </div>
      <div class="page3-bg">
        <div
          style="
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 700px;
            margin-right: 260px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: flex-end;
              width: 100%;
              height: 50%;
            "
          >
            <div
              style="
                height: 100%;
                width: 50%;
                background-color: #ffffff;
                opacity: 0.89;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
              "
            >
              <img
                style="width: 49px; height: 48px; margin-top: 8%"
                src="../assets/rongy.png"
                alt=""
              />
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 28px;
                  color: #333333;
                  line-height: 48px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >企业使命</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #666666;
                  line-height: 34px;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  width: 70%;
                  margin-bottom: 8%;
                "
                >通过技术创新和卓越服务，让企业业务简单、便捷、协同和智能。</span
              >
            </div>
          </div>
          <div style="display: flex; width: 100%; height: 50%">
            <div
              style="
                height: 100%;
                width: 50%;
                background-color: #ffffff;
                opacity: 0.89;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
              "
            >
              <img
                style="width: 49px; height: 48px; margin-top: 8%"
                src="../assets/yuanj.png"
                alt=""
              />
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 28px;
                  color: #333333;
                  line-height: 48px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >价值观</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #666666;
                  line-height: 34px;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  width: 60%;
                  margin-bottom: 8%;
                "
                >专业生存、合作共赢、追求梦想。</span
              >
            </div>
            <div
              class="page3-cpmculture"
              style="
                height: 100%;
                width: 50%;
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
              "
            >
              <img
                style="width: 49px; height: 48px; margin-top: 8%"
                src="../assets/wenh.png"
                alt=""
              />
              <span
                style="
                  font-family: MicrosoftYaHei, MicrosoftYaHei;
                  font-weight: bold;
                  font-size: 28px;
                  color: #FAFAFA;
                  line-height: 48px;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                "
                >企业文化</span
              >
              <span
                style="
                  font-family: MicrosoftYaHei;
                  font-size: 20px;
                  color: #FAFAFA;
                  line-height: 34px;
                  text-align: center;
                  font-style: normal;
                  text-transform: none;
                  width: 65%;
                  margin-bottom: 8%;
                "
                >平等、尊重、共同发展， 成就梦想。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第四页 荣誉资质 -->
    <!-- <div class="about-page4">
      
    </div> -->
    <!-- 第五页 合作伙伴 -->
    <div class="background-layer5" v-show="showPageNo == 3">
      <div class="page5-top">
        <span class="page5-title">合作伙伴</span>
      </div>
      <div class="page5-logos">
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_01.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_02.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_03.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_04.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_05.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_06.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_07.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_08.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_09.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_10.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-single">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_11.png"
            alt=""
          />
        </div>
        <div class="page5-item-bg-double">
          <img
            style="width: 100%; height: 100%"
            src="../assets/hz_12.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue"
import Cookie from "js-cookie";
export default {
  components: {CommonFooter},
  name: "",
  data() {
    return {
      showPageNo : 1
    };
  },
  created() {},
  methods: {
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    changePageNo(num) {
      this.showPageNo = num
    }
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 600px;
    background-image: url("../assets/about_bg1.png");
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 67px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .btn-item:hover {
        background-color: rgba(255, 255, 255,0.15);
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: 869px;
    display: flex;
    background-image: url("../assets/about_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .page3-bg {
    width: 100%;
    height: 700px;
    display: flex;
    background-image: url("../assets/about_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    .page3-cpmculture {
      background-image: url("../assets/about_page3_itembg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .about-page4 {
    width: 100%;
    height: 1153px;
    display: flex;
    background-image: url("../assets/about_bg4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
