import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Font from './assets/fontFamily/font.css'

createApp(App).use(store).use(router).use(ArcoVue).use(ElementPlus).use(Font).mount('#app')
