<template>
  <div class="background-layer6">
      <div class="page6-top">
        <div class="page6-top-left">
          <span class="page6-top-left-title">丰樾科技</span>
          <div class="page6-left-info">
            <img
              style="width: 16px; height: 18px"
              src="../assets/home_page6_location.png"
              alt=""
            />
            <span class="page6-info-content">地址：北京市海淀区丰智东路13号A2栋7层8025室</span>
          </div>
          <div class="page6-left-info">
            <img
              style="width: 17px; height: 15px"
              src="../assets/home_page6_tel.png"
              alt=""
            />
            <span class="page6-info-content">电话：13651076842</span>
          </div>
          <div class="page6-left-info">
            <img
              style="width: 16px; height: 13px"
              src="../assets/home_page6_mail.png"
              alt=""
            />
            <span class="page6-info-content">邮箱：fykj@fengyuecloud.com</span>
          </div>
        </div>
        <div class="page6-top-middle">
          <div class="page6-middle-item">
            <span class="page6-middle-item-title">关于我们</span>
            <span class="page6-middle-content">公司简介</span>
            <span class="page6-middle-content">企业文化</span>
            <span class="page6-middle-content">荣誉资质</span>
          </div>
          <div class="page6-middle-item">
            <span class="page6-middle-item-title">产品与服务</span>
            <span class="page6-middle-content">致知平台</span>
            <span class="page6-middle-content">智服云</span>
            <span class="page6-middle-content">樾畅销</span>
          </div>
          <div class="page6-middle-item">
            <span class="page6-middle-item-title">客户案例</span>
            <!-- <span class="page6-middle-content">智服云平台</span>
            <span class="page6-middle-content">APP</span> -->
          </div>
          <div class="page6-middle-item">
            <span class="page6-middle-item-title">新闻资讯</span>
            <span class="page6-middle-content">公司新闻</span>
            <span class="page6-middle-content">媒体聚焦</span>
          </div>
          <div class="page6-middle-item">
            <span class="page6-middle-item-title">联系我们</span>
            <span class="page6-middle-content">联系我们</span>
            <span class="page6-middle-content">留言板</span>
            <span class="page6-middle-content">人才招聘</span>
          </div>
        </div>
        <div class="page6-top-right">
          <span class="page6-top-right-title">关注微信公众号</span>
          <img
            style="width: 140px; height: 140px; margin-top: 20px;"
            src="../assets/home_gzh.png"
            alt=""
          />
        </div>
      </div>
      <div class="page6-bottom">
        <div class="page6-bottom-part1">
          <img
            style="width: 42px; height: 42px"
            src="../assets/home_page6_media1.png"
            alt=""
          />
          <img
            style="width: 42px; height: 42px; margin-left: 1.5%"
            src="../assets/home_page6_media2.png"
            alt=""
          />
          <img
            style="width: 42px; height: 42px; margin-left: 1.5%"
            src="../assets/home_page6_media3.png"
            alt=""
          />
          <img
            style="width: 42px; height: 42px; margin-left: 1.5%"
            src="../assets/home_page6_media4.png"
            alt=""
          />
          <img
            style="width: 42px; height: 42px; margin-left: 1.5%"
            src="../assets/home_page6_media5.png"
            alt=""
          />
        </div>
        <div class="page6-bottom-part2">
          <span class="page6-bottom-part2-left"
            >Copyright©2016-2024 北京丰樾科技有限公司 All Rights Reserved</span
          >
          <div style="width:auto;margin-right: 9%; padding:3px 0;text-align:center;">
     <p style="float:center;height:20px;line-height:20px;margin: 0px 0px 0px 5px;font-size:15px;">
     <a href="http://beian.miit.gov.cn/" target="_blank">京ICP备2022015790号-2</a >
    </p >
    </div>
          <!-- <div class="page6-bottom-part2-right">
            <span class="page6-bottom-part2-item">法律声明</span>
            <span class="page6-bottom-part2-item">|</span>
            <span class="page6-bottom-part2-item">隐私保护</span>
            <span class="page6-bottom-part2-item">|</span>
            <span class="page6-bottom-part2-item">网站地图</span>
          </div> -->
        </div>
      </div>
    </div>
</template>
   
<script>
export default {
    components: {
  },
  name:'',
  data(){
    return {
        
    }
  },
  created() {
    
  },
  methods: {
  },
}
</script>
   
<style lang="less" scoped>
.background-layer6 {
    width: 100%;
    height: 579px;
    background-image: url("../assets/home_bg6.jpg");
    background-clip: content-box;
    background-position: center center;
    .page6-top {
      width: 100%;
      height: 65%;
      display: flex;
      justify-content: space-between;
      .page6-top-left {
        height: 45%;
        width: 30%;
        display: flex;
        flex-direction: column;
        margin-left: 7%;
        margin-top: 5%;
        .page6-top-left-title {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 2%;
        }
        .page6-left-info {
          display: flex;
          align-items: center;
          .page6-info-content {
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 42px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-left: 3%;
          }
        }
      }
      .page6-top-middle {
        height: 45%;
        width: 50%;
        display: flex;
        margin-top: 5%;
        justify-content: space-between;
        .page6-middle-item {
          display: flex;
          flex-direction: column;
          .page6-middle-item-title {
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            line-height: NaNpx;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-bottom: 13%;
          }
          .page6-middle-content {
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 42px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }
      }
      .page6-top-right {
        height: 45%;
        width: 20%;
        display: flex;
        flex-direction: column;
        margin-left: 7%;
        margin-top: 5%;
        .page6-top-right-title {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          font-size: 20px;
          color: #333333;
          line-height: NaNpx;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }
    }
    .page6-bottom {
      width: 100%;
      height: 35%;
      .page6-bottom-part1 {
        display: flex;
        align-items: center;
        width: 45%;
        height: 50%;
        margin-left: 7%;
      }
      .page6-bottom-part2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50%;
        border-top: 2px solid #f4f4f4;
        .page6-bottom-part2-left {
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          font-size: 16px;
          color: #999999;
          line-height: 45px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 7%;
        }
        .page6-bottom-part2-right {
          display: flex;
          justify-content: space-between;
          margin-right: 9%;
          .page6-bottom-part2-item {
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 45px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>