import http from "../utils/request";

// 用户登录
export const userLogin = (data) => {
    
    return http.post('/users/users/login',data)
    // return http.post('/fsm/api/AppUserLogin',data)
}

// 获取首页顶部四个方块
export const topItem = (data) => {
    // const apiUrl = '/fsm/api/fp_newboxdata';
    const apiUrl = '/orders/orders/fp_newboxdata'
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取首页右侧数值卡片
export const countCard = (data) => {
    // const apiUrl = '/fsm/api/fp_threesimple';
    const apiUrl = '/orders/orders/fp_threesimple';
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取首页满意度饼图
export const getPieData = (data) => {
    // const apiUrl = '/fsm/api/fp_manyi_percent';
    const apiUrl = '/orders/orders/fp_manyi_percent';
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取首页故障分析柱状图
export const getBarData = (data) => {
    // const apiUrl = '/fsm/api/fp_faulttype_count';
    const apiUrl = '/mats/mats/fp_faulttype_count';
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取首页待办工单列表table
export const getOrder = (data) => {
    // const apiUrl = '/fsm/api/fp_wo_notfinish';
    const apiUrl = '/mats/mats/fp_wo_notfinish';
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取首页消息列表
export const getMessage = (data) => {
    // const apiUrl = '/fsm/api/AppEngineerMessage';
    const apiUrl = '/mats/mats/AppEngineerMessage';
    const accessToken = encodeURIComponent(data.access_token);
    const url = `${apiUrl}?access_token=${accessToken}`;
    return http.get(url);
}

// 获取日程列表
export const dateTask = (data) => {
    // const apiUrl = '/fsm/api/AppWoListExpectdate';
    const apiUrl = '/mats/mats/AppWoListExpectdate';
    const accessToken = encodeURIComponent(data.access_token);
    const beginTime = encodeURIComponent(data.expectdate);
    const url = `${apiUrl}?access_token=${accessToken}&expectdate=${beginTime}`;
    return http.get(url);
}

// 消息置为已读
export const messageChangeStatus = (data) => {
    // return http.post('/fsm/api/AppUpdateMessageIsread',data)
    return http.post('/mats/mats/AppUpdateMessageIsread',data)
}

// 全部消息置为已读
export const messageChangeStatusAll = (data) => {
    // return http.post('/fsm/api/AppUpdateMessageIsreadAll',data)
    return http.post('/mats/mats/AppUpdateMessageIsreadAll',data)
}

// 获取当前有任务的日期列表
export const taskDate = (data) => {
    // const apiUrl = '/fsm/api/AppExpectdateHaveWo';
    const apiUrl = '/mats/mats/AppExpectdateHaveWo';
    const accessToken = encodeURIComponent(data.access_token);
    const beginTime = encodeURIComponent(data.expectdate);
    const url = `${apiUrl}?access_token=${accessToken}&expectdate=${beginTime}`;
    return http.get(url);
}