<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
      <div
        class="page1-middle"
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 54px;
          color: #ffffff;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 90px;
        "
      >
        <span>联系我们</span>
      </div>
      <div class="page1-bottom">
        <div class="btn-item" @click="changePageNo(1)">联系我们</div>
        <div class="btn-item" @click="changePageNo(2)">留言板</div>
        <div class="btn-item" @click="changePageNo(3)">人才招聘</div>
      </div>
    </div>
    <!-- 第二页 联系我们 -->
    <div class="contact-page2" v-show="showPageNo == 1">
      <div></div>
      <span
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 42px;
          color: #0b1030;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        "
        >联系我们</span
      >
      <div class="page2-bottom">
        <span
          style="
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: bold;
            font-size: 34px;
            color: #0b1030;
            line-height: 48px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            margin-left: 5%;
            margin-top: 7%;
          "
          >合作共赢</span
        >
        <div
          style="
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 45%;
            width: 90%;
            margin-left: 5%;
            padding-top: 3%;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              height: 100%;
            "
          >
            <img
              style="width: 72px; height: 72px"
              src="../assets/contact_ad.png"
              alt=""
            />
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #999999;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >公司地址</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #666666;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >北京市海淀区丰智东路13号A2栋7层8025室</span
            >
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              height: 100%;
            "
          >
            <img
              style="width: 72px; height: 72px"
              src="../assets/contact_tel.png"
              alt=""
            />
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #999999;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >联系电话</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #666666;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >400-6688-688</span
            >
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              height: 100%;
            "
          >
            <img
              style="width: 72px; height: 72px"
              src="../assets/contact_mail.png"
              alt=""
            />
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #999999;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >邮箱</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                font-size: 20px;
                color: #666666;
                text-align: center;
                font-style: normal;
                text-transform: none;
              "
              >fykj@fengyuecloud.com</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 第二页 留言板 -->
    <div class="contact-page3" v-show="showPageNo == 2">
      <div></div>
      <span
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 42px;
          color: #0b1030;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        "
        >留言板</span
      >
      <div class="page2-bottom">
        <div style="display: flex; width: 100%; height: 100%">
          <div class="contact-left-bg"></div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 55%;
              background-color: #ffffff;
            "
          >
            <div
              style="display: flex; width: 90%; margin-left: 5%; margin-top: 5%"
            >
              <div
                style="
                  display: flex;
                  width: 48%;
                  border-bottom: 1px solid #ebebeb;
                "
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 35px;
                    text-align: justifyLeft;
                    font-style: normal;
                    text-transform: none;
                  "
                  >标题</span
                >
                <input
                  v-model="contactTitle"
                  placeholder=""
                  placeholder-class="input-placeholder"
                  style="
                    border: none;
                    margin-left: 10px;
                    flex: 1;
                    background-color: transparent;
                  "
                />
              </div>
              <div
                style="
                  display: flex;
                  width: 48%;
                  margin-left: 2%;
                  border-bottom: 1px solid #ebebeb;
                "
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 35px;
                    text-align: justifyLeft;
                    font-style: normal;
                    text-transform: none;
                  "
                  >联系人</span
                >
                <input
                  v-model="contactTitle"
                  placeholder=""
                  placeholder-class="input-placeholder"
                  style="
                    border: none;
                    margin-left: 10px;
                    flex: 1;
                    background-color: transparent;
                  "
                />
              </div>
            </div>
            <div
              style="display: flex; width: 90%; margin-left: 5%; margin-top: 5%"
            >
              <div
                style="
                  display: flex;
                  width: 48%;
                  border-bottom: 1px solid #ebebeb;
                "
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 35px;
                    text-align: justifyLeft;
                    font-style: normal;
                    text-transform: none;
                  "
                  >联系电话</span
                >
                <input
                  v-model="contactTitle"
                  placeholder=""
                  placeholder-class="input-placeholder"
                  style="
                    border: none;
                    margin-left: 10px;
                    flex: 1;
                    background-color: transparent;
                  "
                />
              </div>
              <div
                style="
                  display: flex;
                  width: 48%;
                  margin-left: 2%;
                  border-bottom: 1px solid #ebebeb;
                "
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 35px;
                    text-align: justifyLeft;
                    font-style: normal;
                    text-transform: none;
                  "
                  >邮箱</span
                >
                <input
                  v-model="contactTitle"
                  placeholder=""
                  placeholder-class="input-placeholder"
                  style="
                    border: none;
                    margin-left: 10px;
                    flex: 1;
                    background-color: transparent;
                  "
                />
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
              "
            >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                  line-height: 35px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                "
                >留言内容</span
              >
              <a-textarea
                placeholder=""
                style="height: 300px; margin-top: 2%"
                allow-clear
              />
              <div class="submit-btn">提交</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二页 人才招聘 -->
    <div class="contact-page4"  v-show="showPageNo == 3">
      <!-- 标题 -->
      <div
        style="
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: bold;
          font-size: 42px;
          color: #0b1030;
          line-height: 58px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 6%;
        "
      >
        人才招聘
      </div>
      <!-- 标题下的四个图标 -->
      <div
        style="
          display: flex;
          width: 70%;
          height: 8.6%;
          justify-content: space-around;
          margin-top: 5%;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
          "
        >
          <img
            style="width: 72px; height: 72px"
            src="../assets/zp_hr.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #999999;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >联系人</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #666666;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >王女士</span
          >
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
          "
        >
          <img
            style="width: 72px; height: 72px"
            src="../assets/zp_lc.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #999999;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >公司地址</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #666666;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >北京市海淀区丰智东路13号A2栋7层8025室</span
          >
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
          "
        >
          <img
            style="width: 72px; height: 72px"
            src="../assets/zp_tel.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #999999;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >联系电话</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #666666;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >13651076842</span
          >
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
          "
        >
          <img
            style="width: 72px; height: 72px"
            src="../assets/zp_mail.png"
            alt=""
          />
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #999999;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >邮箱</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 20px;
              color: #666666;
              text-align: center;
              font-style: normal;
              text-transform: none;
            "
            >fykj@fengyuecloud.com</span
          >
        </div>
      </div>
      <!-- hc & jd信息 -->
      <div style="width: 74%; height: 26%; margin-top: 4%">
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item name="1">
            <template #title>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 80px);
                  padding-left: 40px;
                "
                @click="rotateControl(1)"
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 22px;
                    color: #333333;
                    font-style: normal;
                  "
                  >JAVA软件开发工程师</span
                >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="font-family: SourceHanSansCN, SourceHanSansCN; font-weight: 400; font-size: 18px; color: #666666; line-height: 1px; text-align: justifyLeft; font-style: normal; text-transform: none;"
                    >招聘人数:1人 | 工作地点:北京 | 截止日期:2024-12-31</span
                  >
                  <div class="hc-arrow-bg">
                    <img
                      :class="{ rotate180: isRotated1, rotate: isRotated1 }"
                      style="width: 16px; height: 16px"
                      src="../assets/hc-info-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              style="
                padding-left: 40px;
                padding-right: 40px;
                width: calc(100% - 80px);
              "
            >
              <div class="hc-header-bottomline"></div>
              <div style="display: flex; width: 100%; padding-top: 25px">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >岗位职责:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                      width: 80%;
                    "
                    >1、与产品经理充分沟通，领会项目的整体结构与核心内容，理解产品的设计思想，了解具体功能的实现细节；
                    <br />2、按计划完成功能模块的程序结构设计、代码编写和单元测试，并提交测试人员进行功能测试；
                    <br />3、解决开发过程和系统运行过程中的问题，确保产品的质量；
                    <br />4、维护项目的原有代码，保证系统的正常有效运行；
                    <br />5、编写程序设计文档、制品部署手册；</span
                  >
                  <div class="hc-send-mail">投递邮箱</div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >任职要求:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                      width: 80%;
                    "
                    >1、具有Java相关后端开发经验，具备相关行业知识或实践经验，具备项目开发和管理经验,能良好地掌握开发速度和质量；<br />
                    2、熟练使用Java语言并掌握面向对象设计思想，熟悉UML；<br />
                    3、熟练使用Eclipse、IntelliJIDEA等开发工具；<br />
                    4、掌握SQL语句编写，有主流数据库（MySQL,Oracle,PostgreSql,SQLServer其中之一）使用经验经验；<br />
                    5、熟悉JavaEE技术体系，熟悉B/S架构，熟悉主流的应用服务器(Tomcat,Jetty,JBoss,Weblogic)；<br />
                    6、熟练使用主流版本管理工具（Git、SVN其中之一）；<br />
                    7、熟练使用Hibernate、Spring、Mybatis框架；<br />
                    8、熟练使用HTML、JavaScript、CSS、具备前端开发框架(Angular2.x+,Vue,ExtJs)使用经验者优先；<br />
                    9、具备CXF、Dubbo、SpringCloud等技能者优先；<br />
                    10、ERP、MES、CRM、O2O等相关软件开发经历优先；<br />
                    11、具有低代码平台开发或基于低代码平台开发的经验者优先；<br />
                    12、对技术研发有热情，有良好的编程习惯，对工作任务有责任心，具备良好的学习能力和独立解决问题的能力，善于沟通，具有良好的团队合作精神。<br />
                  </span>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template #title>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 80px);
                  padding-left: 40px;
                "
                @click="rotateControl(2)"
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 22px;
                    color: #333333;
                    font-style: normal;
                  "
                  >实施顾问/运维顾问</span
                >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="font-family: SourceHanSansCN, SourceHanSansCN; font-weight: 400; font-size: 18px; color: #666666; line-height: 1px; text-align: justifyLeft; font-style: normal; text-transform: none;"
                    >招聘人数:1人 | 工作地点:北京 | 截止日期:2024-12-31</span
                  >
                  <div class="hc-arrow-bg">
                    <img
                      :class="{ rotate180: isRotated2, rotate: isRotated2 }"
                      style="width: 16px; height: 16px"
                      src="../assets/hc-info-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              style="
                padding-left: 40px;
                padding-right: 40px;
                width: calc(100% - 80px);
              "
            >
              <div class="hc-header-bottomline"></div>
              <div style="display: flex; width: 100%; padding-top: 25px">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >岗位职责:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                      width: 80%;
                    "
                    >1、主修经济管理，金融管理，会计财务、人力资源，计算机、物流、国际贸易、市场营销等相关专业优先。
                    <br />
                    2、五官端正，形象良好，有相关工作经验者优先。
                    <br />3、有责任感，团队意识强，有进取心，学习能力强。
                    <br />4、有良好的沟通能力。
                    <br />5、财会专业或财会相关资质证书优先。<br />6、能熟练掌握office办公软件。</span
                  >
                  <div class="hc-send-mail">投递邮箱</div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >任职要求:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                      width: 80%;
                    "
                    >1、用友ERP系统实施或运维5年及以上工作经验；<br />
                    2、负责项目现场的项目实施，及时反馈项目进度、问题难点；<br />
                    3、能够和客户进行简单有效沟通；<br />
                    4、熟练应用Linux、AIX等主流服务器，并简单使用数据库脚本。<br />
                    5、协助进行ERP理论和软件操作技能的培训。<br />
                    6、驻场项目（无需出差）。
                    </span
                  >
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template #title>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 80px);
                  padding-left: 40px;
                "
                @click="rotateControl(3)"
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 22px;
                    color: #333333;
                    font-style: normal;
                  "
                  >Java开发实习生</span
                >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="font-family: SourceHanSansCN, SourceHanSansCN; font-weight: 400; font-size: 18px; color: #666666; line-height: 1px; text-align: justifyLeft; font-style: normal; text-transform: none;"
                    >招聘人数:1人 | 工作地点:北京 | 截止日期:2024-12-31</span
                  >
                  <div class="hc-arrow-bg">
                    <img
                      :class="{ rotate180: isRotated3, rotate: isRotated3 }"
                      style="width: 16px; height: 16px"
                      src="../assets/hc-info-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              style="
                padding-left: 40px;
                padding-right: 40px;
                width: calc(100% - 80px);
              "
            >
              <div class="hc-header-bottomline"></div>
              <div style="display: flex; width: 100%; padding-top: 25px">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >岗位职责:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                    "
                    >1、服从公司领导的工作安排； <br />2、有效利用工作时间，主动、不懂就问、多与同事沟通；
                    <br />3、有团队协助精神，具备良好的学习能力和抗压能力；
                    <br />4、工作认真仔细、责任心强、工作勤奋；</span
                  >
                  <div class="hc-send-mail">投递邮箱</div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >任职要求:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                    "
                    >1、统招本科及以上学历，理工类，软件工程，计算机类，信息安全等相关专业；<br />
                    2、熟悉数据结构、基础算法；<br />
                    3、扎实的java基础知识和功底，熟练运用idea、myeclipse基本开发工具；<br />
                    4、熟悉MySQL等数据库；<br />
                    5、工作认真负责，善于学习，热衷接受和掌握新技术，善于沟通。
                    </span
                  >
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template #title>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 80px);
                  padding-left: 40px;
                "
                @click="rotateControl(4)"
              >
                <span
                  style="
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 400;
                    font-size: 22px;
                    color: #333333;
                    font-style: normal;
                  "
                  >大客户经理</span
                >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="font-family: SourceHanSansCN, SourceHanSansCN; font-weight: 400; font-size: 18px; color: #666666; line-height: 1px; text-align: justifyLeft; font-style: normal; text-transform: none;"
                    >招聘人数:1人 | 工作地点:北京 | 截止日期:2024-12-31</span
                  >
                  <div class="hc-arrow-bg">
                    <img
                      :class="{ rotate180: isRotated4, rotate: isRotated4 }"
                      style="width: 16px; height: 16px"
                      src="../assets/hc-info-arrow.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              style="
                padding-left: 40px;
                padding-right: 40px;
                width: calc(100% - 80px);
              "
            >
              <div class="hc-header-bottomline"></div>
              <div style="display: flex; width: 100%; padding-top: 25px">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >岗位职责:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                    "
                    >1、负责行业内客户的市场信息搜集、整理和反馈，以及潜在客户及目标市场的梳理和发掘； <br />2、通过各种渠道对客户进行销售，完成公司的销售目标；
                    <br />3、协调并有效跟进客户合作项目，快速促进签单；
                    <br />4、拜访客户，搜集有效信息并分析客户需求，制定销售跟计划和策略，做好客户跟进；
                    <br />5、维护客户关系，进行客户定期拜访和回访等工作，挖掘老客户需求，进行多次交易；
                    <br />6、协同产品经理进行客户需求深度开发和方案制定，进行项目落地；
                    <br />7、负责招/投标、商务谈判、报价、合同签订，以及协作推动项目有效验收、收款及售后服务；
                    <br />8、加强与现有客户沟通，监控客户最新动态，维系长期合作的关系，跟进客户回款；
                    <br />9、制定销售方案、年度和季度销售总结及计划；
                    <br />10、负责销售合同的签订、执行跟踪及归档整理。</span
                  >
                  <div class="hc-send-mail">投递邮箱</div>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                  "
                >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #333333;
                    "
                    >任职要求:</span
                  >
                  <span
                    style="
                      font-family: SourceHanSansCN, SourceHanSansCN;
                      font-weight: 500;
                      font-size: 18px;
                      color: #666666;
                    "
                    >1、大专及以上学历，市场营销、计算机、通信等相关专业优先考虑；<br />
                    2、5年及以上销售工作经验，有To B端相关软件销售经验者优先；<br />
                    3、热爱销售工作，追求卓越，突破能力强，具有良好的团队协作意识；<br />
                    4、性格外向，有较强沟通和协作能力，工作作风优良，简单高效、诚信务实，有团队合作意识和较强的执行能力；<br />
                    5、具备良好的人际交往和商务沟通能力，有一定文案功底，善于归纳总结，超强的执行力；<br />
                    6、具备较强的项目运作、公关、洽谈能力，能熟练应用顾问式销售方法挖掘、引导和实现客户需求；<br />
                    7、具备敏锐的市场洞察力、应变能力、创造性的思维能力及稳健的管理能力；<br />
                    8、逻辑表达能力强，行事有强烈的责任感和服务精神，适应能力强；<br />
                    9、做事细致，具有良好的学习能力和亲和力，善于沟通，具有较强的协调能力、执行力和应变能力；<br />
                    10、能适应出差。</span
                  >
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      contactTitle: "",
      contactName: "",
      contactTel: "",
      contactMail: "",
      contactContent: "",
      isRotated1: true,
      isRotated2: false,
      isRotated3: false,
      isRotated4: false,
      activeNames: ["1"],
      showPageNo: 1
    };
  },
  created() {},
  methods: {
    rotateControl(index) {
      if(index == 1) {
        this.isRotated1 = !this.isRotated1;
      }else if (index == 2) {
        this.isRotated2 = !this.isRotated2;
      }else if (index == 3) {
        this.isRotated3 = !this.isRotated3;
      }else {
        this.isRotated4 = !this.isRotated4;
      }
    },
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    changePageNo(num) {
      this.showPageNo = num
    }
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 600px;
    background-image: url("../assets/contact_bg1.png");
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 67px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: calc(100% / 3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .btn-item:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .contact-page2 {
    width: 100%;
    height: 991px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url("../assets/contact_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .page2-bottom {
      width: 74%;
      height: 468px;
      background-image: url("../assets/cantact_dialog_bg.png");
      background-repeat: no-repeat;
      background-size: auto;
      display: flex;
      flex-direction: column;
    }
  }

  .contact-page3 {
    width: 100%;
    height: 991px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url("../assets/contact_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .page2-bottom {
      display: flex;
      flex-direction: column;
      width: 72%;
      height: 70%;
      .contact-left-bg {
        width: 45%;
        height: 100%;
        background-image: url("../assets/contact_send.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .submit-btn {
        background: linear-gradient(to right, #00aaff, #1758d8);
        width: 130px;
        height: 50px;
        margin-top: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #ffffff;
      }
      input:focus {
        outline: none;
      }
    }
  }
  .contact-page4 {
    width: 100%;
    height: 1962px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../assets/contact_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    .hc-arrow-bg {
      background: linear-gradient(to right, #00aaff, #1758d8);
      width: 60px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 70px;
      .rotate180 {
        transition: transform 0.6s;
      }

      .rotate180.rotate {
        transform: rotate(180deg);
      }
    }
    .hc-header-bottomline {
      width: 100%;
      height: 3px;
      background: linear-gradient(to right, #00aaff, #1758d8);
    }
    .hc-send-mail {
      width: 191px;
      height: 60px;
      background: linear-gradient(to right, #00aaff, #1758d8);
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .el-collapse {
      // --el-collapse-border-color: red;
      --el-collapse-header-bg-color: none;
      --el-collapse-content-bg-color: none;
      --el-collapse-header-height: 90px;
      --el-collapse-header-text-color: #666666;
    }
    .el-icon svg {
      display: none;
    }
    .el-collapse-item__arrow {
      display: none;
    }
    .el-icon el-collapse-item__arrow is-active {
      display: none;
    }
    .el-icon el-collapse-item__arrow {
      display: none;
    }

    .el-collapse-item {
      border: 1px solid #00aaff;
      margin-bottom: 20px;
    }
    // .el-collapse-item is-active {
    //   border: 1px solid #00aaff;
    // }
    // .el-collapse-item__content {
    //   border: 1px solid #00aaff;
    // }
  }
}
</style>
