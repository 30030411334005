import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue';
import Login from '../views/Login.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue';
import Schedule from '../views/Schedule.vue';
import News from '../views/News.vue'
import Service from '../views/Service.vue'
import NewsDetail from '../views/NewsDetail.vue'
import NewsDetail1 from '../views/NewsDetail1.vue'
import NewsDetail2 from '../views/NewsDetail2.vue'
import NewsDetail3 from '../views/NewsDetail3.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/schedule',
        name: 'Schedule',
        component: Schedule
      },{
        path: '/about',
        name: 'About',
        component: About
      },{
        path: '/contact',
        name: 'Contact',
        component: Contact
      },{
        path: '/news',
        name: 'News',
        component: News
      },{
        path: '/service',
        name: 'Service',
        component: Service
      },{
        path: '/newsDetail',
        name: 'NewsDetail',
        component: NewsDetail
      },{
        path: '/newsDetail1',
        name: 'NewsDetail1',
        component: NewsDetail1
      },{
        path: '/newsDetail2',
        name: 'NewsDetail2',
        component: NewsDetail2
      },{
        path: '/newsDetail3',
        name: 'NewsDetail3',
        component: NewsDetail3
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
