<template>
  <div class="page1container">
    <div class="content">
      <div class="contentHeader">
        <div class="headerLeft"></div>
        <div class="headerMiddle">
          <a-button type="text" @click="jumpToHome">首页</a-button>
          <a-button type="text" @click="jumpToAbout">关于我们</a-button>
          <a-button type="text" @click="jumpToService">产品中心</a-button>
          <a-button type="text">技术支持</a-button>
          <a-button type="text" @click="jumpToNews">新闻中心</a-button>
          <a-button type="text" @click="jumpToContact">联系我们</a-button>
        </div>
        <div class="headerRight">
          <div class="rightSub" style="margin-right: 10px">
            <div class="rightSearch"></div>
          </div>
          <div class="rightSub">
            <span style="color: white; font-size: 16px">EN</span>
          </div>
        </div>
      </div>
    </div>
    <div class="about-page2">
      <!-- 左边内容 新闻详情 -->
      <div style="width: 70%; height: 100%; border-right: 1px solid #eeeeee">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 16%;
            display: flex;
            flex-direction: column;
            padding-top: 3%;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 40px;
              color: #3d434b;
              line-height: 63px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            "
            >启用新Logo，重塑品牌标识</span
          >
          <div class="news-title-icon"></div>
          <span
            style="
              font-family: HarmonyOS_Sans_Medium;
              font-size: 20px;
              color: #3d434b;
              line-height: 63px;
              margin-top: 30px;
            "
            >2024-04-21 10:11:24</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #3d434b;
              margin-top: 30px;
            "
            >尊敬的客户、合作伙伴：</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
          >
            承蒙您多年来对我公司的鼎力支持与信任，使我公司得以良性持续发展。为了进一步提升公司的企业形象，塑造行业品牌；增进公司Logo
            的识别性和传播性；提高品牌的影响力和竞争力，北京丰樾科技有限公司（以下简称：丰樾科技）决定对
            Logo 进行升级，自即日起正式启用新 Logo，原版 Logo
            将被逐步停止使用。</span
          >

          <div class="logos" style="display: flex;flex-direction: column; margin-top: 20px;">
            <div
              style="display: flex; align-items: center;justify-content: space-around;width: 50%;margin-left: 25%;"
            >
              <img
                style="width: 25%; height: auto"
                src="../assets/news_detail_logo.png"
                alt=""
              />
              <img
                style="width: 32px; height: 32px"
                src="../assets/news_detail2_arrow_right.png"
                alt=""
              />
              <img
                style="width: 25%; height: auto"
                src="../assets/news_detail_logo_new.png"
                alt=""
              />
            </div>

            <div
              style="display: flex; align-items: center;justify-content: space-around;width: 50%;margin-left: 25%;"
            >
            <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 600;
                  font-size: 18px;
                  color: #333333;
                  line-height: 32px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                  margin-top: 20px;
                  margin-left: 8px
                "
                >旧Logo</span
              >
              <div></div>
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 600;
                  font-size: 18px;
                  color: #333333;
                  line-height: 32px;
                  text-align: justifyLeft;
                  font-style: normal;
                  text-transform: none;
                  margin-top: 20px;
                "
                >新Logo</span
              >
            </div>
          </div>

          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >新的Logo以简洁、大气、易识别为设计原则，采用了更加明快的色彩和更加流畅的线条，使整体形象更加年轻、时尚、有活力。同时，新的Logo也更加注重细节和品质，体现了我们对产品和服务精益求精的态度。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >1）图形整体由品牌名称"Fy" 首字母进行设计强化品牌，
            具有高度识别性。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >2）方块像素与字母y的结合：传达了企业在IT软件领域的领导地位和专业服务的形象，展示了丰樾科技对于现代科技的追求和创新精神，体现了企业的专业性、技术实力和现代科技感。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >3）图形紧密连接，体现企业团结、力量的象征。又寓意在新市场格局中，企业与客户互惠互利，共同发展的和谐关系。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >4）上部分似翅膀，体现企业具有向上奋发的势能，象征辉煌远大的未来前程和勃勃生机与发展动力，寓意不断腾飞的发展事业和未来蓝图。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
            "
            >5）构图大气简约、国际化，一目了然易懂易记，符合行业属性与时代审美。</span
          >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: 400;
              font-size: 18px;
              color: #666666;
              line-height: 32px;
              text-align: justifyLeft;
              font-style: normal;
              text-transform: none;
              margin-top: 20px;
              text-indent: 30px;
              margin-bottom: 80px;
            "
            >这次Logo的变更，不仅是我们品牌形象的一次升级，更是我们对未来发展的信心和承诺。我们将继续秉承“通过技术创新和卓越服务，让企业业务简单、便捷、协同和智能”的使命，为客户提供更加优质的产品和服务，为员工创造更加广阔的发展平台，为合作伙伴带来更加丰厚的回报。</span
          >
        </div>
        <!-- 底部返回列表 -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 78%;
            margin-left: 16%;
            padding-bottom: 80px;
            border-top: 1px solid #eeeeee;
            padding-top: 60px;
          "
        >
          <div style="display: flex; flex-direction: column">
            <div
              style="display: flex; padding-bottom: 10px"
              v-for="(item, index) in newsList"
              :key="index"
              @click="jumpOtherNews(index)"
            >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #666666;
                "
                >{{ item.newsOrder }}</span
              >
              <span
                style="
                  font-family: SourceHanSansCN, SourceHanSansCN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #999999;
                "
                >{{ item.newsTitle }}</span
              >
            </div>
          </div>
          <img
            style="width: 191px; height: 60px"
            src="../assets/news_detail_return.png"
            alt=""
            @click="backToList"
          />
        </div>
      </div>
      <!-- 右边内容 推荐新闻 -->
      <div style="width: 30%; height: 100%">
        <div
          style="
            width: 80%;
            height: 100%;
            margin-left: 10%;
            padding-top: 9%;
            display: flex;
            flex-direction: column;
          "
        >
          <span
            style="
              font-family: SourceHanSansCN, SourceHanSansCN;
              font-weight: bold;
              font-size: 28px;
              color: #333333;
            "
            >推荐新闻</span
          >

          <div
            style="display: flex; flex-direction: column; padding-top: 7%"
            v-for="(item, index) in newsListRight"
            :key="index"
            @click="jumpOtherNewsRight(index)"
          >
            <span
              style="
                font-family: HarmonyOS_Sans_Medium;
                font-size: 16px;
                color: #333333;
              "
              >{{ item.newsDate }}</span
            >
            <span
              style="
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                margin-top: 3%;
                line-height: 29px;
                width: 65%;
              "
              >{{ item.newsTitle }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 第六页 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import CommonFooter from "../components/CommonFooter.vue";
import Cookie from "js-cookie";
export default {
  components: { CommonFooter },
  name: "",
  data() {
    return {
      newsList: [
        {
          newsDate: "2024.05.21",
          newsTitle: "我司商标注册申请已初步审定并公告",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.12.05",
          newsTitle: "丰樾科技携手中国长城，为信创领军企业数字化转型添砖加瓦",
          newsOrder: "下一篇：",
        },
      ],
      newsListRight: [
        {
          newsDate: "2024.05.21",
          newsTitle: "我司商标注册申请已初步审定并公告",
          newsOrder: "下一篇：",
        },
        {
          newsDate: "2023.12.05",
          newsTitle: "丰樾科技携手中国长城，为信创领军企业数字化转型添砖加瓦",
          newsOrder: "上一篇：",
        },
        {
          newsDate: "2023.09.04",
          newsTitle:
            "(转)集智达AMS系统项目启动会成功举办，助力公司售后服务升级",
          newsOrder: "下一篇：",
        },
      ],
    };
  },
  created() {},
  methods: {
    jumpToAbout() {
      this.$router.push("/about");
    },
    jumpToContact() {
      this.$router.push("/contact");
    },
    jumpToNews() {
      this.$router.push("/news");
    },
    jumpToService() {
      this.$router.push("/service");
    },
    jumpToHome() {
      this.$router.push("/home");
    },
    jumpOtherNews(index) {
      if (index == 0) {
        this.$router.push("/newsDetail3");
      } else {
        this.$router.push("/newsDetail");
      }
    },
    jumpOtherNewsRight(index) {
      if (index == 0) {
        this.$router.push("/newsDetail3");
      } else if (index == 1) {
        this.$router.push("/newsDetail");
      } else {
        this.$router.push("/newsDetail1");
      }
    },
    backToList() {
      this.$router.push("/news");
    },
  },
};
</script>

<style lang="less" scoped>
.page1container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  .content {
    width: 100%;
    height: 142px;
    background: linear-gradient(to right, #00aaff, #1758d8);
    background-clip: content-box;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contentHeader {
      display: flex;
      justify-content: space-between;
      padding-top: 45px;
      padding-left: 153px;
      padding-right: 153px;
      align-items: center;
      .headerLeft {
        width: 191px;
        height: 54px;
        background-image: url("../assets/header_logo.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .headerMiddle {
        .arco-btn-text,
        .arco-btn-text[type="button"],
        .arco-btn-text[type="submit"] {
          color: white;
        }
        .arco-btn-text:hover,
        .arco-btn-text[type="button"]:hover,
        .arco-btn-text[type="submit"]:hover {
          background-color: transparent;
        }
        .arco-btn-size-medium {
          font-size: 16px;
        }
      }
      .headerRight {
        display: flex;
        justify-content: space-between;
        .rightSub {
          text-align: center;
          width: 75px;
          height: 40px;
          border-radius: 20px;
          border: 1px solid white;
          box-shadow: inset 2px 2px 20px rgb(5, 153, 233);
          display: flex;
          justify-content: center;
          align-items: center;
          .rightSearch {
            width: 18px;
            height: 18px;
            background-image: url("../assets/header_search.png");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 3;
          }
        }
      }
    }
    .page1-bottom {
      width: 72%;
      margin-left: 14%;
      margin-bottom: -40px;
      height: 80px;
      display: flex;
      background-image: url("../assets/about_tab_bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      .btn-item {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        font-size: 20px;
        color: #fdfdfd;
        line-height: 58px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .about-page2 {
    width: 100%;
    height: auto;
    display: flex;
    background-image: url("../assets/news_detail_bg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    .news-title-icon {
      width: 22px;
      height: 4px;
      background: linear-gradient(to right, #00aaff, #1758d8);
      margin-top: 20px;
    }
  }
  .about-page3 {
    width: 100%;
    height: 1146px;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/news_bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    .bottom-news-item {
      width: 30%;
      height: 42%;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-color: #ffffff;
      .bottom-news-date {
        font-family: SegoeUI;
        font-size: 24px;
        color: #666666;
        line-height: 66px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
      }
      .bottom-news-title {
        font-family: MicrosoftYaHei;
        font-size: 19px;
        color: #0b1030;
        line-height: 29px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -8%;
      }
      .bottom-news-content {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #777985;
        line-height: 26px;
        text-align: justifyLeft;
        font-style: normal;
        text-transform: none;
        width: 80%;
        margin-left: 10%;
        margin-top: -7%;
      }
    }
  }
  .about-page4 {
    width: 100%;
    height: 1153px;
    display: flex;
    background-image: url("../assets/about_bg4.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .background-layer5 {
    width: 100%;
    height: 739px;
    background-image: url("../assets/home_bg5.jpg");
    background-clip: content-box;
    background-position: center center;
    .page5-top {
      height: 20%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      .page5-title {
        height: 49px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 50px;
        color: #f6fafc;
        line-height: 52px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
    .page5-logos {
      padding-left: 100px;
      padding-right: 100px;
      height: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page5-item-bg-single {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
      }
      .page5-item-bg-double {
        width: calc((100% - 310px) / 12);
        aspect-ratio: 1 / 1;
        margin-top: 100px;
      }
    }
  }
}
</style>
