<template>
  <div class="header">
    <div class="left">
      <img src="../assets/home/logo_small.png" alt="" class="logo" />
    </div>
    <div class="middle"></div>
    <div class="right">
      <a-dropdown>
        <img
          style="width: 18px; height: 18px"
          src="../assets/home/home-setting.png"
        />
        <template #content>
          <a-dsubmenu value="日志管理">
            <template #default>日志管理</template>
            <template #content>
              <a-doption>API调用日志</a-doption>
              <a-doption>IM聊天日志</a-doption>
              <a-doption>短信日志</a-doption>
              <a-doption>手机验证码</a-doption>
              <a-doption>修改密码</a-doption>
            </template>
          </a-dsubmenu>
          <a-doption>参数设置</a-doption>
          <a-doption>微信设置</a-doption>
          <a-doption>系统管理</a-doption>
          <a-doption>切换系统</a-doption>
        </template>
      </a-dropdown>
      <img
        style="width: 18px; height: 18px"
        src="../assets/home/home-mail.png"
        @click="showMessage"
      />
      <a-popover position="bl" trigger="click">
        <img class="userIcon" :src="`https://www.zhfyun.com/fsm/upload/${userData.headimg}`" />
        <template #content>
          <div class="infoPop">
            <div class="top" style="display: flex;border-bottom: 1px solid rgba(29,33,41,0.1);padding-bottom: 11px;">
              <div class="topLeft">
                <img :src="`https://www.zhfyun.com/fsm/upload/${userData.headimg}`"
                 style="width: 53px;height: 53px;border-radius: 50%;" />
              </div>
              <div style="display: flex;flex-direction: column;margin-left: 15px;">
                <span style="color: rgba(29,33,41,0.9);font-size: 21px;">{{ userData.name }}</span>
                <span style="color: rgba(29,33,41,0.5);font-size: 17px;">{{ userData.position }}</span>
                <span style="color: rgba(29,33,41,0.5);font-size: 17px;">{{ userData.mobile }}</span>
              </div>
            </div>
            <div class="second" style="border-bottom: 1px solid rgba(29,33,41,0.1);padding-bottom: 11px;">
              <div style="width: calc(100% - 11px);height: 43px;
              display: flex;align-items: center;padding-left: 11px;" 
              :style="{ backgroundColor: settingHover ? 'rgba(29,33,41,0.05)' : '#fff' }"
              @mouseover="settingHoverd" @mouseleave="settingLeave" @click="settingClick">账号设置</div>
              <div style="width: calc(100% - 11px);height: 43px;
              display: flex;align-items: center;padding-left: 11px;"
              :style="{ backgroundColor: modifyHover ? 'rgba(29,33,41,0.05)' : '#fff' }"
              @mouseover="modifyHoverd" @mouseleave="modifyLeave" @click="modifyClick">修改密码</div>
            </div>
            <div class="third">
              <div style="width: calc(100% - 11px);height: 43px;
              display: flex;align-items: center;padding-left: 11px;"
              :style="{ backgroundColor: logoutHover ? 'rgba(29,33,41,0.05)' : '#fff' }"
              @mouseover="logoutHoverd" @mouseleave="logoutLeave" @click="logoutClick">退出登录</div>
            </div>
          </div>
        </template>
      </a-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      settingHover: false,
      modifyHover: false,
      logoutHover: false,
      userData:{},
      options: [
        {
          value: "rizhiguanli",
          label: "日志管理",
          children: [
            {
              value: "dy",
              label: "API调用日志",
            },
            {
              value: "IM",
              label: "IM聊天日志",
            },
            {
              value: "dx",
              label: "短信日志",
            },
            {
              value: "yzm",
              label: "手机验证码",
            },
            {
              value: "xgmm",
              label: "修改密码",
            },
          ],
        },
        {
          value: "canshushezhi",
          label: "参数设置",
        },
        {
          value: "weixinshezhi",
          label: "微信设置",
        },
        {
          value: "xitongguanli",
          label: "系统管理",
        },
        {
          value: "qiehuanxitong",
          label: "切换系统",
        },
      ],
    };
  },
  created() {},
  methods: {
    showMessage() {
      this.$store.commit("changeState");
    },
    settingHoverd() {
      this.settingHover = true
    },
    settingLeave() {
      this.settingHover = false
    },
    settingClick() {

    },
    modifyHoverd() {
      this.modifyHover = true
    },
    modifyLeave() {
      this.modifyHover = false
    },
    modifyClick() {
      
    },
    logoutHoverd() {
      this.logoutHover = true
    },
    logoutLeave() {
      this.logoutHover = false
    },
    logoutClick() {
      
    }
  },
  mounted() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.userData = userInfo
    console.log(this.userData)
  }
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 1px -1px rgba(29, 33, 41, 0.02),
    0px 1px 2px 0px rgba(29, 33, 41, 0.02),
    0px 1px 4px 1px rgba(29, 33, 41, 0.02);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .left {
    display: flex;
    padding-left: 12px;
    align-items: center;
    width: 264px;
    height: 56px;
    .logo {
      width: 180px;
      height: 40px;
    }
    .company {
      margin-left: 8px;
      margin-right: 8px;
    }
    .cloud {
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(29, 33, 41, 0.9);
      line-height: 26px;
    }
  }
  .right {
    width: 111px;
    height: 56px;
    margin-right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .userIcon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
}
</style>
