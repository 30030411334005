<template>
  <div class="container">
    <common-schedule></common-schedule>
  </div>
</template>
   
<script>
import CommonSchedule from '../components/CommonSchedule.vue'
   
export default {
  components: { CommonSchedule },
  name:'',
  data(){
    return {
    }
  },
  created() {
    
  },
  methods: {
  
  },
}
</script>
   
<style lang="less" scoped>
.container {
    overflow: hidden;
    background-color: #EEF0F5;
    display: flex;
    justify-content: space-between;
    height: calc(100%);
    padding-bottom: 16px;
}
  
</style>